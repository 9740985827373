import { useMemo } from "react";

import TokenAInput from "Pages/OpenPosition/Components/tokenAInput";
import TokenBInput from "Pages/OpenPosition/Components/tokenBInput";
import ExchangeBtn from "Pages/OpenPosition/Components/exchangeBtn";
import ActionBtn from "Pages/OpenPosition/Components/actionButton";
import { useSelector } from "Store";
import SwapHeader from "./header";
import PriceCalculator from "./priceCalculator";
import { ErrorNotice } from "Components/noticeTiles";

const LiquidtyAdder = () => {
  const isTxInProgress = useSelector((state) => state.loadings.isTxInProgress);
  const isPoolInfoLoading = useSelector((state) => state.loadings.isPoolInfoLoading);
  const errors = useSelector((state) => state.open.errors);

  const isDisabled = useMemo(
    () => isTxInProgress || isPoolInfoLoading,
    [isPoolInfoLoading, isTxInProgress]
  );
  return (
    <div
      className={`flex flex-col bg-base-content p-5 rounded ${
        isDisabled ? "pointer-events-none" : ""
      }`}
    >
      <SwapHeader />
      <TokenAInput />
      <ExchangeBtn />
      <TokenBInput />
      <PriceCalculator />
      {errors.length ? <ErrorNotice text={errors} /> : null}
      <ActionBtn />
    </div>
  );
};

export default LiquidtyAdder;
