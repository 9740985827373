export const NATIVE_SOL_TOKENINFO = {
  address: "So11111111111111111111111111111111111111112",
  chainId: 101,
  decimals: 9,
  name: "Solana",
  symbol: "SOL",
  logoURI:
    "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png",
  tags: ["old-registry"],
  extensions: { coingeckoId: "wrapped-solana" },
};

export const WSOL_TOKENINFO = {
  address: "wSo11111111111111111111111111111111111111112",
  chainId: 101,
  decimals: 9,
  name: "Wrapped SOL",
  symbol: "wSOL",
  logoURI:
    "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png",
  tags: ["old-registry"],
};

export const USDC_TOKENINFO = {
  address: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
  chainId: 101,
  decimals: 6,
  name: "USD Coin",
  symbol: "USDC",
  logoURI:
    "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png",
  tags: ["old-registry", "solana-fm"],
  extensions: { coingeckoId: "usd-coin" },
};

export const MESH_TOKENINFO = {
  address: "MESHwqmXvAmKpDYSgRZkm9D5H8xYSCVixeyZoePHn4G",
  chainId: 101,
  decimals: 9,
  name: "Mesh Protocol",
  symbol: "MESH",
  logoURI:
    "https://bafkreiathub4nwtjremi6ze4zun3ojkmafznreabdg3nzoidtd4qvghmem.ipfs.nftstorage.link/",
  tags: ["unknown"],
  extensions: { coingeckoId: "mesh-protocol" },
};

export const MESH_ADDRESS = "MESHwqmXvAmKpDYSgRZkm9D5H8xYSCVixeyZoePHn4G";
