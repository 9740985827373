import { AnchorWallet } from "@solana/wallet-adapter-react";
import { Connection, Signer, VersionedTransaction } from "@solana/web3.js";
import { toast } from "react-toastify";

import CachedService from "Classes/cachedService";

export abstract class BaseLiquidityManager {
  protected abstract wallet: AnchorWallet;
  protected abstract connection: Connection;

  abstract openPosition(): Promise<void>;
  abstract closePosition(): Promise<void>;
  abstract increaseLiquidity(): Promise<void>;
  abstract decreaseLiquidity(): Promise<void>;
  abstract executeTransaction(
    transaction: VersionedTransaction,
    signers: Signer[]
  ): Promise<string | void>;
}

export const TxFailChore = (FailToast: JSX.Element) => {
  toast.dismiss();
  CachedService.errorToast(FailToast);
  // fetch pool again
};
