import numbro from "numbro";
import * as anchor from "@coral-xyz/anchor";
import { parseUnits as parseUnitsEthers } from "ethers";

import { Environment } from "Types/misc";
import { solanaExplorerUrl } from "Constants/misc";
import { OrcaPoolToken, Token } from "Types/pools";
import { TokenInfo } from "Types/tokens";

export const formatDollarAmount = (num: number | undefined | null, digits = 2, round = true) => {
  if (num === 0) return "$0.00";
  if (!num) return "-";
  if (num < 0.001 && digits <= 3) {
    return "<$0.001";
  }
  if (num < 0.01 && digits <= 2) {
    return "<$0.01";
  }

  return numbro(num).formatCurrency({
    average: round,
    mantissa: num > 1000 ? 2 : digits,
    abbreviations: {
      million: "M",
      billion: "B",
    },
  });
};

export const formatTokenAmount = (num: number | undefined, digits = 2) => {
  if (num === 0) return "0";
  if (!num) return "-";
  if (num < 0.001 && digits <= 3) {
    return "<0.001";
  }
  if (num < 0.01 && digits <= 2) {
    return "<0.01";
  }

  let formattedAmount = numbro(num)
    .formatCurrency({
      average: true,
      mantissa: num >= 1000 ? 2 : digits,
      abbreviations: {
        million: "M",
        billion: "B",
      },
    })
    .replace("$", "");

  formattedAmount = formattedAmount.replace(".00", "");
  return formattedAmount;
};

export function parseUnits(_num: number) {
  return new anchor.BN(parseUnitsEthers(_num.toString(), 9).toString());
}

export function formatUnits(_num: anchor.BN | number, decimals: number) {
  const divisor = 10 ** decimals;
  return typeof _num === "number" ? _num / divisor : parseFloat(_num.toString()) / divisor;
}

export const generateExplorerTxLink = (txId?: string) => {
  return `${solanaExplorerUrl}/tx/${txId}?cluster=${
    process.env.REACT_APP_ENVIRONMENT === Environment.dev ? "devnet" : "mainnet"
  }`;
};

export const truncateDigits = (num: number) => {
  return Math.floor(num * 100) / 100;
};

export const exactAmountInDecimals = (amount: number, decimals: number) => {
  return Number.isInteger(amount) ? amount.toString() : amount.toFixed(decimals).replace(/0+$/, "");
};

export const convertToJupiterTokenFormat = (token: Token): TokenInfo => {
  return {
    address: token.mint,
    name: token.name,
    chainId: 101,
    decimals: token.decimals,
    logoURI: token.logoURI,
    symbol: token.symbol,
    tags: [],
    extensions: { coingeckoId: token.coingeckoId },
  };
};
