import { useEffect } from "react";
import { useAnchorWallet, useConnection } from "@solana/wallet-adapter-react";
import * as anchor from "@coral-xyz/anchor";

import CachedService from "Classes/cachedService";
import { fetchPricesOfUserTokens, fetchUserTokens } from "Utils/fetchers";
import { dispatch, useSelector } from "Store";
import { setIsPriceLoading } from "Store/Reducers/loadings";
import PriceManagerClass from "Classes/priceManager";
import { checkIfwSOLAddress } from "Utils/judger";
import {
  setCurrentPrices,
  setIsInSufficientBalanceA,
  setIsInSufficientBalanceB,
  setUserBalanceA,
  setUserBalanceB,
} from "Store/Reducers/session";
import { NATIVE_SOL_TOKENINFO } from "Constants/tokens";
import { minimumSOLbalance } from "Constants/misc";

const Listeners = () => {
  const { connection } = useConnection();
  const anchrWallet = useAnchorWallet();

  const successTxCount = useSelector((state) => state.session.successTxCount);
  const userBalanceA = useSelector((state) => state.session.userBalanceA);
  const userBalanceB = useSelector((state) => state.session.userBalanceB);
  const amountA = useSelector((state) => state.open.amountA);
  const amountB = useSelector((state) => state.open.amountB);
  const tokenA = useSelector((state) => state.open.tokenA);
  const tokenB = useSelector((state) => state.open.tokenB);
  const userTokens = useSelector((state) => state.session.userTokens);

  useEffect(() => {
    let provider: anchor.Provider;
    if (anchrWallet && connection) {
      provider = new anchor.AnchorProvider(connection, anchrWallet, {});
      anchor.setProvider(provider);
      CachedService.setupWhirlpool(connection, anchrWallet);
      CachedService.setupRaydium(connection, anchrWallet);
    }
  }, [anchrWallet, connection]);

  // fetch user tokens balances
  useEffect(() => {
    (async () => {
      if (anchrWallet) {
        const freshUserTokens = await fetchUserTokens(connection, anchrWallet.publicKey);
        fetchPricesOfUserTokens(freshUserTokens);
      }
    })();
  }, [connection, anchrWallet, successTxCount]);

  // update the balances amount with the balances of current selected tokens
  useEffect(() => {
    dispatch(setUserBalanceA(userTokens[tokenA.address]?.uiAmountString ?? "0"));
    dispatch(setUserBalanceB(userTokens[tokenB.address]?.uiAmountString ?? "0"));
  }, [tokenA.address, tokenB.address, userTokens]);

  // price data of newly selected pool tokens
  useEffect(() => {
    (async () => {
      dispatch(setIsPriceLoading(true));
      const prices = await new PriceManagerClass([
        checkIfwSOLAddress(tokenA.address),
        checkIfwSOLAddress(tokenB.address),
      ]).fetchTokensPrices();
      dispatch(setCurrentPrices(prices));
      dispatch(setIsPriceLoading(false));
    })();
  }, [tokenA.address, tokenB.address]);

  // insufficient balance A
  useEffect(() => {
    dispatch(
      setIsInSufficientBalanceA(
        amountA
          ? tokenA.address === NATIVE_SOL_TOKENINFO.address
            ? +userBalanceA - +amountA < minimumSOLbalance
            : +amountA > +userBalanceA
          : false
      )
    );
  }, [amountA, tokenA.address, userBalanceA]);

  // insufficient balance B
  useEffect(() => {
    dispatch(
      setIsInSufficientBalanceB(
        amountB
          ? tokenB.address === NATIVE_SOL_TOKENINFO.address
            ? +userBalanceB - +amountB < minimumSOLbalance
            : +amountB > +userBalanceB
          : false
      )
    );
  }, [amountB, tokenB.address, userBalanceB]);

  return <></>;
};

export default Listeners;
