import { AnchorWallet } from "@solana/wallet-adapter-react";
import { Connection, Signer, VersionedTransaction } from "@solana/web3.js";
import {
  ApiV3PoolInfoConcentratedItem,
  ClmmPositionLayout,
  getEpochInfo,
  getTransferAmountFeeV2,
  Raydium,
  ReturnTypeGetLiquidityAmountOut,
  TickUtils,
  TxVersion,
} from "@raydium-io/raydium-sdk-v2";
import Decimal from "decimal.js";
import { BN } from "@coral-xyz/anchor";
import { toast } from "react-toastify";

import {
  DepositSuccessToast,
  TxCanceledToast,
  TxFailedToast,
  TxProgressToast,
  TxSignToast,
  WithdrawSuccessToast,
} from "Components/toasts";
import { TokenInputType } from "Types/tokens";
import { store } from "Store";
import { BaseLiquidityManager, TxFailChore } from "./interface";
import CachedService from "Classes/cachedService";
import { incrementSuccessTxCount, resetPercentages } from "Store/Reducers/session";
import { setIsApprovalPending, setIsTxInProgress } from "Store/Reducers/loadings";
import { transactionSenderAndConfirmationWaiter } from "Utils/txSender";
import { simulateTransaction } from "Utils/fetchers";

export class RaydiumManager extends BaseLiquidityManager {
  protected wallet: AnchorWallet;
  protected connection: Connection;

  constructor(wallet: AnchorWallet, connection: Connection) {
    super();
    this.wallet = wallet;
    this.connection = connection;
  }

  async openPosition(): Promise<void> {
    try {
      const raydium = CachedService.raydium;

      if (raydium) {
        store.dispatch(setIsTxInProgress(true));

        const { OtherAmount, inputAmount, inputType, computedSlippage, upperTick, lowerTick } =
          this.extractParamsForTx();

        const poolInfo = CachedService.OpenPositionClass.pool as ApiV3PoolInfoConcentratedItem;
        const qoute = CachedService.OpenPositionClass.qoute as ReturnTypeGetLiquidityAmountOut;

        const { transaction, signers } = await raydium.clmm.openPositionFromLiquidity({
          poolInfo,
          tickUpper: Math.max(lowerTick, upperTick),
          tickLower: Math.min(lowerTick, upperTick),
          ownerInfo: {
            useSOLBalance: true,
          },
          txVersion: TxVersion.V0,
          amountMaxA: inputType === TokenInputType.A ? inputAmount : OtherAmount,
          amountMaxB: inputType === TokenInputType.B ? inputAmount : OtherAmount,
          liquidity: new BN(
            new Decimal(qoute.liquidity.toString()).mul(1 - computedSlippage).toFixed(0)
          ),
        });

        const txId = await this.executeTransaction(transaction, signers);
        store.dispatch(setIsTxInProgress(false));
        console.log("txId", txId);
      }
    } catch (error) {
      console.log("error", error);
      TxFailChore(<TxFailedToast />);
      store.dispatch(setIsTxInProgress(false));
    }
  }

  async increaseLiquidity(): Promise<void> {
    try {
      const raydium = CachedService.raydium;
      if (raydium) {
        store.dispatch(setIsTxInProgress(true));

        const { OtherAmount, currentPosition, inputAmount, inputType, computedSlippage } =
          this.extractParamsForTx();

        const poolInfo = CachedService.OpenPositionClass.pool as ApiV3PoolInfoConcentratedItem;
        const qoute = CachedService.OpenPositionClass.qoute as ReturnTypeGetLiquidityAmountOut;

        const allPositions = await raydium.clmm.getOwnerPositionInfo({
          programId: poolInfo.programId,
        });

        const ownerPosition = allPositions.find((p) => p.poolId.toBase58() === poolInfo.id);

        if (currentPosition && ownerPosition) {
          const { transaction, signers } = await raydium.clmm.increasePositionFromLiquidity({
            poolInfo,
            ownerPosition,
            ownerInfo: {
              useSOLBalance: true,
            },
            liquidity: new BN(
              new Decimal(qoute.liquidity.toString()).mul(1 - computedSlippage).toFixed(0)
            ),
            amountMaxA: inputType === TokenInputType.A ? inputAmount : OtherAmount,
            amountMaxB: inputType === TokenInputType.B ? inputAmount : OtherAmount,
            txVersion: TxVersion.V0,
            // optional: set up priority fee here
            // computeBudgetConfig: {
            //   units: 600000,
            //   microLamports: 100000000,
            // },
          });

          const txId = await this.executeTransaction(transaction, signers);
          store.dispatch(setIsTxInProgress(false));
          console.log("txId", txId);
        }
      }
    } catch (error) {
      console.log("error", error);
      TxFailChore(<TxFailedToast />);
      store.dispatch(setIsTxInProgress(false));
    }
  }

  async closePosition(): Promise<void> {
    const raydium = CachedService.raydium;

    try {
      if (raydium) {
        store.dispatch(setIsTxInProgress(true));
        const { open, app } = store.getState();
        const { slippage, currentSlippage } = app;
        const { currentPosition } = open;

        const computedSlippage = +slippage[currentSlippage];

        if (currentPosition) {
          const poolInfo = (
            await raydium.api.fetchPoolById({
              ids: currentPosition.poolAddress,
            })
          )[0] as ApiV3PoolInfoConcentratedItem;

          const allPositions = await raydium.clmm.getOwnerPositionInfo({
            programId: poolInfo.programId,
          });

          const ownerPosition = allPositions.find((p) => p.poolId.toBase58() === poolInfo.id);

          if (ownerPosition) {
            const { signers, transaction } = await this.getDecreaseLiquidityTx(
              currentPosition.amountTokenA,
              currentPosition.amountTokenB,
              poolInfo,
              raydium,
              computedSlippage,
              ownerPosition
            );

            console.log(await simulateTransaction(this.connection, transaction));

            const txId = await this.executeTransaction(transaction, signers, WithdrawSuccessToast);
            store.dispatch(setIsTxInProgress(false));
            console.log("txId", txId);
          }
        }
      }
    } catch (error) {
      console.log("error", error);
      TxFailChore(<TxFailedToast />);
      store.dispatch(setIsTxInProgress(false));
    }
  }

  async getDecreaseLiquidityTx(
    amountA: string | number,
    amountB: string | number,
    poolInfo: ApiV3PoolInfoConcentratedItem,
    raydium: Raydium,
    slippage: number,
    ownerPosition: ClmmPositionLayout
  ) {
    try {
      const [_amountMinA, _amountMinB] = [
        new BN(
          new Decimal(amountA)
            .mul(1 - slippage)
            .mul(10 ** poolInfo.mintA.decimals)
            .toFixed(0)
        ),
        new BN(
          new Decimal(amountB)
            .mul(1 - slippage)
            .mul(10 ** poolInfo.mintB.decimals)
            .toFixed(0)
        ),
      ];
      const epochInfo = await getEpochInfo(this.connection);
      const { fee: feeA = new BN(0) } = getTransferAmountFeeV2(
        _amountMinA,
        poolInfo.mintA.extensions.feeConfig,
        epochInfo!,
        false
      );
      const { fee: feeB = new BN(0) } = getTransferAmountFeeV2(
        _amountMinB,
        poolInfo.mintB.extensions.feeConfig,
        epochInfo!,
        false
      );

      const { transaction, signers } = await raydium.clmm.decreaseLiquidity({
        poolInfo,
        ownerPosition,
        ownerInfo: {
          useSOLBalance: true,
          closePosition: true,
        },
        liquidity: ownerPosition.liquidity,
        amountMinA: _amountMinA.sub(feeA),
        amountMinB: _amountMinB.sub(feeB),
        txVersion: TxVersion.V0,
      });

      return { transaction, signers };
    } catch (error) {
      throw error;
    }
  }

  async decreaseLiquidity(): Promise<void> {}

  async executeTransaction(
    transaction: VersionedTransaction,
    signers: Signer[],
    SuccessToast: ({ txId }: { txId: string }) => JSX.Element = DepositSuccessToast
  ) {
    CachedService.TxProgressToast(<TxSignToast />);
    store.dispatch(setIsApprovalPending(true));

    return await this.wallet
      .signTransaction(transaction as VersionedTransaction)
      .then(async (signedTx) => {
        signedTx.sign(signers);
        toast.dismiss();
        CachedService.TxProgressToast(<TxProgressToast />);
        store.dispatch(setIsApprovalPending(false));
        const { blockhash, lastValidBlockHeight } = await this.connection.getLatestBlockhash();
        const transactionResponse = await transactionSenderAndConfirmationWaiter({
          connection: this.connection,
          serializedTransaction: signedTx.serialize(),
          blockhashWithExpiryBlockHeight: {
            blockhash,
            lastValidBlockHeight,
          },
        });

        // If we are not getting a response back, the transaction has not confirmed.
        if (!transactionResponse) {
          console.error("Transaction not confirmed", transactionResponse);
          TxFailChore(<TxFailedToast />);
          return;
        }

        if (transactionResponse.meta?.err) {
          console.error("transactionResponse in error", transactionResponse);
          TxFailChore(<TxFailedToast />);
          return;
        }

        if (transactionResponse) {
          const txId = transactionResponse.transaction.signatures[0];
          console.log("success transactionResponse", transactionResponse, txId);
          toast.dismiss();
          CachedService.successToast(<SuccessToast txId={txId} />);
          store.dispatch(incrementSuccessTxCount());
          store.dispatch(resetPercentages());
          return txId;
        }
      })
      .catch((err) => {
        console.log("sign transaction failed", err);
        store.dispatch(setIsApprovalPending(false));
        TxFailChore(<TxCanceledToast />);
      });
  }

  async getPosition(positionMint: string) {}

  private extractParamsForTx() {
    const { open, app } = store.getState();
    const { slippage, currentSlippage } = app;
    const { inputType, amountA, amountB, currentPosition, minPriceRange, maxPriceRange } = open;
    const poolInfo = CachedService.OpenPositionClass.pool as ApiV3PoolInfoConcentratedItem;
    const qoute = CachedService.OpenPositionClass.qoute as ReturnTypeGetLiquidityAmountOut;
    const computedSlippage = Number(slippage[currentSlippage]) / 100;
    const inputTokenInfo = inputType === TokenInputType.A ? poolInfo.mintA : poolInfo.mintB;
    // do not need to adjust for price slippage here as the focus is on input amount
    const inputAmount = new BN(
      new Decimal(inputType === TokenInputType.A ? amountA : amountB)
        .mul(10 ** inputTokenInfo.decimals)
        .toFixed(0)
    );
    // other amount can be adjusted to slippage just as a precaution to make sure successful tx
    const OtherAmount = new BN(
      new Decimal(
        (inputType === TokenInputType.A
          ? qoute.amountSlippageB.amount
          : qoute.amountSlippageA.amount
        ).toString()
      )
        .mul(1 + computedSlippage)
        .toFixed(0)
    );

    const { tick: lowerTick } = TickUtils.getPriceAndTick({
      poolInfo,
      price: new Decimal(minPriceRange),
      baseIn: true,
    });

    const { tick: upperTick } = TickUtils.getPriceAndTick({
      poolInfo,
      price: new Decimal(maxPriceRange),
      baseIn: true,
    });

    return {
      upperTick,
      lowerTick,
      OtherAmount,
      inputAmount,
      currentPosition,
      inputType,
      computedSlippage,
    };
  }
}
