import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Connection, LAMPORTS_PER_SOL, PublicKey, VersionedTransaction } from "@solana/web3.js";
import WebWorkers from "web-worker";
import axios from "axios";

import PriceManagerClass from "Classes/priceManager";
import { NATIVE_SOL_TOKENINFO, WSOL_TOKENINFO } from "Constants/tokens";
import { store } from "Store";
import { setBalanceLoading } from "Store/Reducers/loadings";
import { setUserTokenBalances, setUserTokens } from "Store/Reducers/session";

import { ParsedTokenInfo, UserTokenBalances, UserTokens } from "Types/tokens";
import { formatUnits } from "./format";
import { LIQUIDITY_POOLS_LIST_URL } from "Constants/endpoints";
import { PoolData } from "Types/pools";
import { groupByKey } from "./helpers";
import PoolsCacheService from "Classes/poolCache";

export const fetchUserTokens = async (connection: Connection, walletPubKey: PublicKey | null) => {
  let userTokens: UserTokens = {};
  try {
    store.dispatch(setBalanceLoading(true));
    const lamportBalance = await connection.getBalance(walletPubKey as PublicKey);
    if (Boolean(lamportBalance)) {
      const solAmount = lamportBalance / LAMPORTS_PER_SOL;
      userTokens[NATIVE_SOL_TOKENINFO.address] = {
        amount: lamportBalance.toString(),
        decimals: 9,
        uiAmount: solAmount,
        uiAmountString: solAmount.toString(),
      };
    }
    const { value: tokenHoldings } = await connection.getParsedTokenAccountsByOwner(
      walletPubKey as PublicKey,
      {
        programId: TOKEN_PROGRAM_ID,
      }
    );
    if (tokenHoldings.length > 0) {
      tokenHoldings.forEach((token) => {
        const parseInfo = token.account.data.parsed.info as ParsedTokenInfo | undefined;
        if (parseInfo) {
          // check if user holds some wSOL then add it into the balances as wSOL
          userTokens[parseInfo.isNative ? WSOL_TOKENINFO.address : parseInfo.mint] =
            parseInfo.tokenAmount;
        }
      });
    }
    store.dispatch(setUserTokens(userTokens));
    store.dispatch(setBalanceLoading(false));
  } catch (error) {
    console.log("Unable to fetch user Tokens");
    store.dispatch(setBalanceLoading(false));
  }
  return userTokens;
};

export const fetchPricesOfUserTokens = async (userTokens: UserTokens) => {
  const userTokenAddresses = Object.keys(userTokens);
  const balances: UserTokenBalances = {};
  let tempUserTokens = { ...userTokens };
  delete tempUserTokens[WSOL_TOKENINFO.address]; // delete this address since it is arbitrary and does not exist in real
  const prices = await new PriceManagerClass(Object.keys(tempUserTokens)).fetchTokensPrices();
  userTokenAddresses.forEach((tokenAddress) => {
    const token = userTokens[tokenAddress];
    const tokenBalance = formatUnits(Number(token.amount), token.decimals);
    const tokenPrice =
      tokenAddress === WSOL_TOKENINFO.address // check if user holds wsol then add the price of sol
        ? prices?.[NATIVE_SOL_TOKENINFO.address]?.price ?? 0
        : prices?.[tokenAddress]?.price ?? 0;

    const tokenAmount = tokenPrice * tokenBalance;
    balances[tokenAddress] = {
      balance: tokenBalance,
      amount: tokenAmount,
    };
  });
  store.dispatch(setUserTokenBalances(balances));
};

export const fetchExtendedPoolsInfo = async (addresses: string[]) => {
  try {
    const { data } = await axios.get<PoolData[]>(
      `${LIQUIDITY_POOLS_LIST_URL}/pools/addresses?addresses=${addresses.join(",")}`
    );

    if (data) {
      return groupByKey(data, "address") as Record<string, PoolData>;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};

export const findPoolAddressInCachedPoolsList = (poolAddress: string): Promise<PoolData> => {
  return new Promise((resolve, reject) => {
    const url = new URL("../../public/workers.js", import.meta.url);

    const worker = new WebWorkers(url, { type: "module" });

    worker.postMessage({
      action: "findPool",
      payload: { allPools: PoolsCacheService.allPools, poolAddress },
    });

    worker.addEventListener("message", (event) => {
      if (event.data.success) {
        resolve(event.data.data);
      } else {
        reject(new Error(event.data.error));
      }
      worker.terminate();
    });
  });
};

export const simulateTransaction = async (
  connection: Connection,
  transaction: VersionedTransaction
) => {
  let error = null;
  try {
    // We first simulate whether the transaction would be successful
    const { value: simulatedTransactionResponse } = await connection.simulateTransaction(
      transaction,
      {
        replaceRecentBlockhash: true,
        commitment: "processed",
      }
    );
    console.log("simulatedTransactionResponse", simulatedTransactionResponse);
    const { err } = simulatedTransactionResponse;
    error = err;
  } catch (errorCatch) {
    console.log("Simulation Didn't even started");
    error = errorCatch;
  }
  return error;
};
