import React, { useState, useEffect, useRef } from "react";

import CachedService from "Classes/cachedService";

const radius = 10; // You can adjust the radius as needed
const strokeWidth = 2.4; // Adjust the stroke width if needed

// Calculating the circumference of the circle
const circumference = 2 * Math.PI * radius;

// Determine the size of the SVG based on the radius and stroke width
const svgSize = radius * 2 + strokeWidth * 2;
const totalTime = 120_000; // 20 seconds
const startOffset = circumference; // Updated to match the circumference
const endOffset = 0;
const step = (startOffset - endOffset) / totalTime;

const CircleAnimation: React.FC = () => {
  const [offset, setOffset] = useState(circumference);
  const intervalRef = useRef<number | null>(null);

  const stopAnimation = () => {
    const loader = document.getElementById("qoute-loader");
    if (loader) {
      loader.style.display = "none";
    }
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    CachedService.startGetPoolInfoTimer = startAnimation;
    CachedService.stopGetPoolInfoTimer = stopAnimation;
    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const onCompletion = () => {
    CachedService.OpenPositionClass.fetchPoolData();
  };

  const startAnimation = () => {
    const loader = document.getElementById("qoute-loader");
    if (loader) {
      loader.style.display = "block";
    }
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
    }
    setOffset(startOffset); // Reset the offset to the start
    intervalRef.current = window.setInterval(() => {
      setOffset((prevOffset) => {
        const newOffset = prevOffset - step * 100; // Update every 100ms
        if (newOffset <= endOffset) {
          stopAnimation();
          onCompletion(); // Call the completion callback
          return endOffset;
        }
        return newOffset;
      });
    }, 100);
  };

  const fetchAgain = () => {
    CachedService.stopGetPoolInfoTimer();
    CachedService.OpenPositionClass.fetchPoolData();
  };

  return (
    <div
      id="qoute-loader"
      role="button"
      className="cursor-pointer"
      onClick={fetchAgain}
      style={{ display: "none" }}
    >
      <svg width={`${svgSize}px`} height={`${svgSize}px`} viewBox={`0 0 ${svgSize} ${svgSize}`}>
        <defs>
          <linearGradient id="circleGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#ff6a25" />
            <stop offset="50%" stopColor="#cd42ff" />
            <stop offset="100%" stopColor="#8fb9fc" />
          </linearGradient>
        </defs>
        <circle
          className="fill-transparent"
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          stroke="#000000"
        ></circle>
        <circle
          className="fill-transparent"
          strokeLinecap="round"
          strokeLinejoin="round"
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          transform={`rotate(-90 ${radius + strokeWidth} ${radius + strokeWidth})`}
          style={{ strokeDasharray: circumference, strokeDashoffset: offset }}
          stroke="url(#circleGradient)"
        ></circle>
      </svg>
      {/* <button className="text-primary" onClick={startAgain}>
        Start Again
      </button> */}
    </div>
  );
};

export default CircleAnimation;
