import { useSelector } from "Store";
import { DexType, PoolData } from "Types/pools";
import { formatDollarAmount } from "Utils/format";
import OrcaIcon from "Assets/Svgs/orca.png";
import RaydiumIcon from "Assets/Svgs/RaydiumIcon.svg";

const PoolInfo = ({ currentPool }: { currentPool: PoolData }) => {
  const dexType = useSelector((state) => state.open.currentPool?.dexType);
  return (
    <>
      <div className="flex justify-between items-center p-4">
        <div className="flex items-center gap-4">
          <div className="flex items-center">
            <img
              src={currentPool.tokenA.logoURI}
              width={25}
              height={25}
              className="rounded-full"
              alt="token1-img"
              title={currentPool.tokenA.symbol}
            />
            <img
              src={currentPool.tokenB.logoURI}
              width={25}
              height={25}
              className="rounded-full"
              alt="token2-img"
              title={currentPool.tokenB.symbol}
            />
          </div>
          <div className="flex gap-2">
            <span className="font-semibold text-xl text-primary">{`${currentPool.tokenA.symbol} / ${currentPool.tokenB.symbol}`}</span>
            <span className="text-xs text-primary light-gradient-bg rounded-full p-1 w-14 text-center mt-1">
              {currentPool.lpFeeRate * 100}%
            </span>
          </div>
        </div>
        <div className="flex items-center justify-between w-2/5">
          <img
            src={dexType === DexType.ORCA ? OrcaIcon : RaydiumIcon}
            width={25}
            height={25}
            alt="dexIcon"
            title={dexType}
          />
          <div className="text-secondary font-medium">
            Liquidity{" "}
            <span className="text-primary font-semibold">
              {formatDollarAmount(currentPool.tvl)}
            </span>
          </div>
          <div className="text-secondary font-medium">
            Volume 24H{" "}
            <span className="text-primary font-semibold">
              {formatDollarAmount(currentPool.volume.day)}
            </span>
          </div>
          <div className="text-secondary font-medium">
            Fees 24H{" "}
            <span className="text-primary font-semibold">
              {formatDollarAmount(currentPool.feeApr.day)}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default PoolInfo;
