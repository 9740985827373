import { setIsSettingsOpen } from "Store/Reducers/session";
import CircleAnimation from "./looper";
import GearIcon from "Assets/Svgs/gear.svg";
import { dispatch } from "Store";

const Header = () => {
  return (
    <div className="flex items-center justify-between mb-3">
      <p className="font-bold text-primary text-2xl">Deposit</p>
      <div className="flex items-center">
        <CircleAnimation />
        <img
          src={GearIcon}
          alt="settings"
          className="icon cursor-pointer accent-on-hover ml-4"
          onClick={() => dispatch(setIsSettingsOpen(true))}
        />
      </div>
    </div>
  );
};

export default Header;
