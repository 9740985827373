import { useEffect } from "react";

import CachedService from "Classes/cachedService";
import { NATIVE_SOL, minimumSOLbalance } from "Constants/misc";
import { dispatch, useSelector } from "Store";
import { setCurrentPool, setErrors } from "Store/Reducers/open";
import { resetPercentages } from "Store/Reducers/session";
import { ManageLiqduidityType } from "Types/pools";

const Listenerss = () => {
  const currentPool = useSelector((state) => state.open.currentPool);
  const tokenA = useSelector((state) => state.open.tokenA);
  const tokenB = useSelector((state) => state.open.tokenB);
  const isInSufficientBalanceA = useSelector((state) => state.session.isInSufficientBalanceA);
  const isInSufficientBalanceB = useSelector((state) => state.session.isInSufficientBalanceB);
  const isTxInProgress = useSelector((state) => state.loadings.isTxInProgress);

  useEffect(() => {
    return () => {
      // reset all values of pool
      dispatch(setCurrentPool({ poolData: undefined, type: ManageLiqduidityType.Open }));
      dispatch(resetPercentages());
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (currentPool) {
        await CachedService.OpenPositionClass.fetchPoolData();
      }
    })();
  }, [currentPool]);

  // warning on low sol balance
  useEffect(() => {
    if (
      (tokenA.address === NATIVE_SOL && isInSufficientBalanceA) ||
      (tokenB.address === NATIVE_SOL && isInSufficientBalanceB)
    ) {
      dispatch(
        setErrors([`You need at least ${minimumSOLbalance} SOL to pay for fees and deposits`])
      );
    } else {
      dispatch(setErrors([]));
    }
  }, [isInSufficientBalanceA, isInSufficientBalanceB, tokenA.address, tokenB.address]);

  // stop auto qoutation if transaction is in progress
  useEffect(() => {
    if (isTxInProgress) {
      CachedService.stopGetPoolInfoTimer();
    }
  }, [isTxInProgress]);

  // useEffect(() => {
  //   fetchPoolDetails();
  //   const interval = setInterval(async () => {
  //     await fetchPoolDetails();
  //   }, 60000);

  //   return () => clearInterval(interval);
  // }, []);

  return <></>;
};

export default Listenerss;
