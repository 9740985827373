import localforage from "localforage";
import axios from "axios";

import { store } from "Store";
import { ALL_POOLS_API } from "Constants/endpoints";
import {
  setIsInitialPoolsLoading,
  setIsMorePoolsLoading,
  setIsPoolsLoadingFromCache,
} from "Store/Reducers/loadings";
import { DexType, PoolData } from "Types/pools";
import { setLastPoolsListUpdated } from "Store/Reducers/app";

class PoolsCache {
  allPools: PoolData[] = [];
  raydiumPools: PoolData[] = [];
  orcaPools: PoolData[] = [];

  loadPoolsList = async () => {
    store.dispatch(setIsPoolsLoadingFromCache(true));
    const pools = await this.getPoolsList();
    if (pools) {
      this.mountPoolsInClassVariables(pools);
    }
    store.dispatch(setIsPoolsLoadingFromCache(false));
  };

  mountPoolsInClassVariables(pools: PoolData[]) {
    this.allPools = pools;
    this.raydiumPools = pools.filter((pool) => pool.dexType === DexType.RAYDIUM);
    this.orcaPools = pools.filter((pool) => pool.dexType === DexType.ORCA);
  }

  getPoolsList = async () => {
    try {
      return (await localforage.getItem("pools")) as PoolData[] | null;
    } catch (error) {
      console.error("Error fetching pool list from localforage:", error);
      return null;
    }
  };

  setPoolsList = async (pools: PoolData[]) => {
    try {
      await localforage.setItem("pools", pools);
      store.dispatch(setLastPoolsListUpdated(Date.now()));
      return true;
    } catch (error) {
      console.error("Error storing pool list:", error);
      throw Error("Error storing pool list:");
    }
  };

  appendAndSetPoolsList = async (poolsToAppend: PoolData[]) => {
    try {
      const pools = await this.getPoolsList();
      if (pools) {
        pools.push(...poolsToAppend);
        this.setPoolsList(pools);
        return true;
      }
    } catch (error) {
      return error;
    }
  };

  async fetchPoolsInChunks() {
    try {
      store.dispatch(setIsInitialPoolsLoading(true));
      const initialPools = await this.fetchPoolsApi(0, 500);
      this.mountPoolsInClassVariables(initialPools);
      await this.setPoolsList(initialPools);
      store.dispatch(setIsInitialPoolsLoading(false));
      store.dispatch(setIsMorePoolsLoading(true));
      const restOfPools = await this.fetchPoolsApi(501, -1);
      await this.appendAndSetPoolsList(restOfPools);
    } catch (error) {
      console.log(error);
    }
  }

  fetchPoolsList = async (start: number, end: number) => {
    try {
      store.dispatch(setIsPoolsLoadingFromCache(true));
      const poolRes = await axios.get<PoolData[]>(`${ALL_POOLS_API}?start=${start}&end=${end}`);

      if (poolRes.status === 200) {
        this.setPoolsList(poolRes.data);
        store.dispatch(setIsPoolsLoadingFromCache(false));
      }
    } catch (error) {
      console.log("Error fetching pool list", error);
    }
  };

  fetchPoolsApi = async (start: number, end: number) => {
    const poolRes = await axios.get<PoolData[]>(`${ALL_POOLS_API}?start=${start}&end=${end}`);
    if (poolRes.status === 200) {
      return poolRes.data;
    } else throw Error("Pools fetching failed");
  };
}

const PoolsCacheService = new PoolsCache();

export default PoolsCacheService;
