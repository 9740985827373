import { useEffect, useState } from "react";
import { ElementSingle } from "Types/hooks";
import { getSingleElement } from "Utils/helpers";
import useResizeObserver from "./useResizeObserver";

/**
 * only itself(ref)
 *
 * this hooks build on assumption: resize of a child will resize his parent. so just observe it's parent node.
 *
 * @param ref
 * @param callback
 */
export default function useElementSizeRectDetector(ref: ElementSingle): {
  width: number | undefined;
  height: number | undefined;
} {
  const [width, setWidth] = useState<number | undefined>(undefined);
  const [height, setHeight] = useState<number | undefined>(undefined);

  const updateRect = () => {
    const el = getSingleElement(ref);
    if (!el) return;
    const rect = el.getBoundingClientRect();
    setWidth(rect.width);
    setHeight(rect.height);
  };

  useEffect(updateRect, [ref]);
  useResizeObserver(ref, updateRect);
  return { width, height };
}
