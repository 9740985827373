import { ChangeEventHandler } from "react";

export const RangeInput = ({
  value,
  onChange,
  text,
}: {
  value: number;
  onChange: ChangeEventHandler<HTMLInputElement>;
  text: string;
}) => {
  return (
    <div className="input-container">
      <input
        type="number"
        value={value}
        className="input-with-gradient-border"
        onChange={onChange}
      />
      <span className="sol-text">{text}</span>
    </div>
  );
};
