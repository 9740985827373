import pako from "pako";

import { MESH_SWAP_URL } from "Constants/misc";
import { isObject } from "./judger";
import { ElementRefs, ElementSingle } from "Types/hooks";
import { RefObject } from "react";
import { DecimalUtil } from "@orca-so/common-sdk";
import { BN } from "@coral-xyz/anchor";

export const shortenURL = (tokenDetails: { [key: string]: string | number }) => {
  const jsonString = JSON.stringify(tokenDetails);
  const compressedBytes = pako.gzip(jsonString);
  const encodedString = Buffer.from(compressedBytes).toString("base64");
  const url = `${MESH_SWAP_URL}/?data=${encodeURIComponent(encodedString)}`;
  //   const url = `http://localhost:3001/?data=${encodeURIComponent(encodedString)}`;
  return url;
};

export function getElementsFromRef(refs: ElementRefs) {
  const getRef = <T>(ref: T | RefObject<T>): T =>
    (isObject(ref) && "current" in ref ? ref.current : ref) as T;
  return shakeFalsyItem(flap(getRef(refs)).map((ref) => getRef(ref)));
}

export function getSingleElement(ref: ElementSingle) {
  const el = isObject(ref) && "current" in ref ? ref.current : ref;
  return el ?? undefined;
}

export function flap<T>(arr: T): T extends Array<infer U> ? U[] : T[] {
  // @ts-expect-error force type
  return Array.isArray(arr) ? arr : [arr];
}

export function shakeFalsyItem<T>(arr: readonly T[]): NonNullable<T>[] {
  return arr.filter(Boolean) as NonNullable<T>[];
}

export function groupByKey(data: Record<string, any>[], key: string) {
  return data.reduce((acc, token) => {
    const data = Object.values(token);
    acc[data[0][key]] = data[0];
    return acc;
  }, {});
}

export const tokenBPriceFromTokenA = (tokenAPrice: number, tokenAWorthInTokenB: number) => {
  return tokenAPrice / tokenAWorthInTokenB;
};

export const formatUnits = (amount: string | number, decimals: number) => {
  return DecimalUtil.fromBN(new BN(amount), decimals).toFixed(decimals);
};
