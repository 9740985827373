import { useMemo } from "react";
import { NATIVE_SOL_TOKENINFO } from "Constants/tokens";
import { dispatch, useSelector } from "Store";
import { setAmountA, setAmountB } from "Store/Reducers/open";
import { setPercentageA, setPercentageB } from "Store/Reducers/session";

import { Percentage } from "Types/reducers";
import { TokenInputType } from "Types/tokens";
import { exactAmountInDecimals } from "Utils/format";
import CachedService from "Classes/cachedService";

export const PercentageGroup = ({
  decimals,
  tokenType,
}: {
  decimals: number;
  tokenType: TokenInputType;
}) => {
  const percentageA = useSelector((state) => state.session.percentageA);
  const percentageB = useSelector((state) => state.session.percentageB);
  const userBalanceA = useSelector((state) => state.session.userBalanceA);
  const userBalanceB = useSelector((state) => state.session.userBalanceB);
  const tokenA = useSelector((state) => state.open.tokenA);
  const tokenB = useSelector((state) => state.open.tokenB);

  const percentage = useMemo(
    () => (tokenType === TokenInputType.A ? percentageA : percentageB),
    [percentageA, percentageB, tokenType]
  );

  const onPercentageClick = (percentage: Percentage) => {
    let balance = tokenType === TokenInputType.A ? userBalanceA : userBalanceB;
    let token = tokenType === TokenInputType.A ? tokenA : tokenB;
    let setTokenAmount = tokenType === TokenInputType.A ? setAmountA : setAmountB;
    let setPercentage = tokenType === TokenInputType.A ? setPercentageA : setPercentageB;
    if (+balance > 0) {
      let updatedAmount = +balance;
      if (
        percentage === Percentage._100 &&
        token.address === NATIVE_SOL_TOKENINFO.address &&
        +balance > 0.01
      ) {
        // do this incase of native max sol to save some sol for transaction
        updatedAmount = +balance - 0.01;
      }
      dispatch(setTokenAmount(exactAmountInDecimals(updatedAmount * percentage, decimals)));
      dispatch(setPercentage(percentage));
      CachedService.OpenPositionClass.updateQouteAmounts();

      // if (!isWrapUnwrapIntent) {
      //   dispatch(setQouteLoading(true));
      //   fetchRoutePlan(jupiter);
      // } else {
      //   dispatch(setAmountB(exactAmountInDecimals(updatedAmount * percentage, decimals)));
      // }
    }
  };

  return (
    <div className="flex flex-row items-center justify-end flex-1">
      <SingleBox
        percent={Percentage._10}
        selected={percentage === Percentage._10}
        setSelected={onPercentageClick}
      />
      <SingleBox
        percent={Percentage._25}
        selected={percentage === Percentage._25}
        setSelected={onPercentageClick}
      />
      <SingleBox
        percent={Percentage._50}
        selected={percentage === Percentage._50}
        setSelected={onPercentageClick}
      />
      <SingleBox
        percent={Percentage._75}
        selected={percentage === Percentage._75}
        setSelected={onPercentageClick}
      />
      <SingleBox
        percent={Percentage._100}
        selected={percentage === Percentage._100}
        setSelected={onPercentageClick}
      />
    </div>
  );
};

type SingleBoxProps = {
  percent: Percentage;
  selected: boolean;
  setSelected: (Percentage: Percentage) => void;
};

const MapPercentage: Partial<Record<Percentage, string>> = {
  [Percentage._10]: "10%",
  [Percentage._25]: "25%",
  [Percentage._50]: "50%",
  [Percentage._75]: "75%",
  [Percentage._100]: "Max",
};

const SingleBox = ({ percent, selected, setSelected }: SingleBoxProps) => {
  return (
    <div className="bg-base-100 text-center py-0 rounded w-[16%] flex items-center justify-end">
      <p
        className={`font-semibold text-xs sm:text-sm text-secondary w-fit cursor-pointer ${
          selected ? "mesh-text-gradient" : ""
        }`}
        onClick={() => setSelected(percent)}
      >
        {MapPercentage[percent]}
      </p>
    </div>
  );
};
