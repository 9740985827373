import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DexTabs, Percentage, SessionReducer } from "Types/reducers";
import { TokensPrices, UserTokenBalances, UserTokens } from "Types/tokens";

const initialState: SessionReducer = {
  percentageA: Percentage._0,
  percentageB: Percentage._0,
  successTxCount: 0,
  isInSufficientBalanceA: false,
  isInSufficientBalanceB: false,
  userTokens: {},
  userTokenBalances: {},
  currentPrices: {},
  userBalanceA: "0",
  userBalanceB: "0",
  isSettingsOpen: false,
  tab: DexTabs.ALL,
};

const session = createSlice({
  name: "session",
  initialState,
  reducers: {
    setPercentageA(state, action: PayloadAction<Percentage>) {
      state.percentageA = action.payload;
    },
    setPercentageB(state, action: PayloadAction<Percentage>) {
      state.percentageB = action.payload;
    },
    resetPercentages(state) {
      state.percentageA = Percentage._0;
      state.percentageB = Percentage._0;
    },
    setIsInSufficientBalanceA(state, action: PayloadAction<boolean>) {
      if (state.isInSufficientBalanceA !== action.payload) {
        state.isInSufficientBalanceA = action.payload;
      }
    },
    setIsInSufficientBalanceB(state, action: PayloadAction<boolean>) {
      if (state.isInSufficientBalanceB !== action.payload) {
        state.isInSufficientBalanceB = action.payload;
      }
    },
    incrementSuccessTxCount(state) {
      state.successTxCount = state.successTxCount + 1;
    },
    setUserTokens(state, action: PayloadAction<UserTokens>) {
      state.userTokens = action.payload;
    },
    setUserTokenBalances(state, action: PayloadAction<UserTokenBalances>) {
      state.userTokenBalances = action.payload;
    },
    setCurrentPrices(state, action: PayloadAction<TokensPrices>) {
      state.currentPrices = action.payload;
    },
    setUserBalanceA(state, action: PayloadAction<string>) {
      state.userBalanceA = action.payload;
    },
    setUserBalanceB(state, action: PayloadAction<string>) {
      state.userBalanceB = action.payload;
    },
    setIsSettingsOpen(state, action: PayloadAction<boolean>) {
      state.isSettingsOpen = action.payload;
    },
    setDexTab(state, action: PayloadAction<DexTabs>) {
      state.tab = action.payload;
    },
  },
});

export default session.reducer;

export const {
  setPercentageA,
  setPercentageB,
  incrementSuccessTxCount,
  setIsInSufficientBalanceA,
  setIsInSufficientBalanceB,
  setUserTokens,
  setUserTokenBalances,
  setCurrentPrices,
  setUserBalanceA,
  setUserBalanceB,
  resetPercentages,
  setIsSettingsOpen,
  setDexTab,
} = session.actions;
