import { useMemo } from "react";
import { Axis as d3Axis, axisBottom, NumberValue, ScaleLinear, select } from "d3";
// import styled from '@emotion/styled'

// const StyledGroup = styled.g`
//   line {
//     display: none;
//   }

//   text {
//     color: #7780a0;
//     transform: translateY(5px);
//   }
// `

const Axis = ({ axisGenerator }: { axisGenerator: d3Axis<NumberValue> }) => {
  const axisRef = (axis: SVGGElement) => {
    axis &&
      select(axis)
        .call(axisGenerator)
        .call((g) => g.select(".domain").remove());
  };

  return <g ref={axisRef} />;
};

export const AxisBottom = ({
  xScale,
  innerHeight,
  offset = 0,
}: {
  xScale: ScaleLinear<number, number>;
  innerHeight: number;
  offset?: number;
}) =>
  useMemo(
    () => (
      <div className="translate-x-0">
        <Axis axisGenerator={axisBottom(xScale).ticks(6)} />
      </div>
    ),
    [innerHeight, offset, xScale]
  );
//transform={`translate(0, ${innerHeight + offset})`}
