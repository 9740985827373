import { useMemo } from "react";
import debounce from "lodash.debounce";

import CachedService from "Classes/cachedService";
import { Input } from "Components/input";
import { dispatch, useSelector } from "Store";
import { setAmountB } from "Store/Reducers/open";
import { setPercentageB } from "Store/Reducers/session";
import { LiquidityPositionStatus } from "Types/pools";
import { Percentage } from "Types/reducers";
import { TokenInputType } from "Types/tokens";

const TokenBInput = () => {
  const amountB = useSelector((state) => state.open.amountB);
  const tokenB = useSelector((state) => state.open.tokenB);
  const isInSufficientBalanceB = useSelector((state) => state.session.isInSufficientBalanceB);
  const isPriceLoading = useSelector((state) => state.loadings.isPriceLoading);
  const inputType = useSelector((state) => state.open.inputType);
  const liquidityPositionStatus = useSelector((state) => state.open.liquidityPositionStatus);

  const debounced = useMemo(
    () =>
      debounce(() => {
        CachedService.OpenPositionClass.updateQouteAmounts();
      }, 500),
    []
  );

  const handleAmountChange = (amount: string) => {
    dispatch(setPercentageB(Percentage._0));
    dispatch(setAmountB(amount));
    debounced();
  };

  const disableInput = useMemo(
    () => liquidityPositionStatus === LiquidityPositionStatus.after_price,
    [liquidityPositionStatus]
  );

  const ontokenClick = () => {
    // if (selectedToken !== TokenInputType.B)
    //   dispatch(setSelectedToken(TokenInputType.B));
    // dispatch(setIsTokenListOpen(true));
  };
  return (
    <div
      id="token-input-B-container"
      className={"gradient-border !rounded !mt-3 "
        .concat(inputType === TokenInputType.B ? "" : "gradient-border-transparent ")
        .concat(disableInput ? "pointer-events-none " : "")}
      style={{ filter: disableInput ? "blur(1.5px)" : "" }}
    >
      <div className="bg-base-100 pt-4 px-4 pb-2 rounded">
        <Input
          id="token-input-B"
          amount={amountB}
          token={tokenB}
          setAmount={handleAmountChange}
          showPercentage
          subHeading=""
          ontokenClick={ontokenClick}
          loading={false}
          priceLoading={isPriceLoading}
          editable={true}
          tokenType={TokenInputType.B}
          isInSufficientBalance={isInSufficientBalanceB}
        />
      </div>
    </div>
  );
};

export default TokenBInput;
