import { useMemo, useState } from "react";

import { dispatch, useSelector } from "Store";
import { setIsSettingsOpen } from "Store/Reducers/session";
import backIcon from "Assets/Svgs/arrow_down.svg";
import { resetSwapSettings, saveSwapSettings } from "Store/Reducers/app";
import SlippageSelection from "Components/slippageSelection";
import { SlippageLevels } from "Types/reducers";
import { AMOUNT_INPUT_REGEX } from "Constants/misc";
import { validateDecimalPlaces } from "Utils/judger";
import CachedService from "Classes/cachedService";

const SettingsModal = () => {
  const slippage = useSelector((state) => state.app.slippage);
  const currentSlippage = useSelector((state) => state.app.currentSlippage);
  const isSettingsOpen = useSelector((state) => state.session.isSettingsOpen);

  const [slippageLocal, setSlippageLocal] = useState(slippage);
  const [currentSlippageLocal, setCurrentSlippageLocal] = useState(currentSlippage);

  const closeSettingModal = () => {
    dispatch(setIsSettingsOpen(false));
    resetToSavedChanges();
  };

  const resetToSavedChanges = () => {
    setSlippageLocal({ ...slippage });
    setCurrentSlippageLocal(currentSlippage);
  };

  const resetToDefaulLocalChanges = () => {
    setSlippageLocal({
      low: 0.5,
      medium: 1,
      high: 2,
      custom: "",
    });
    setCurrentSlippageLocal(SlippageLevels.medium);
  };

  const handleSlippageClick = (level: SlippageLevels) => {
    setCurrentSlippageLocal(level);
    clearCustomSlippageLocal();
  };

  const handleCustomSlippageChange = (text: string) => {
    if (text.match(AMOUNT_INPUT_REGEX)) {
      if (validateDecimalPlaces(text, 2)) {
        setSlippageLocal({
          ...slippageLocal,
          custom: text,
        });
      }
    } else {
      clearCustomSlippageLocal();
    }
  };

  const clearCustomSlippageLocal = () => setSlippageLocal({ ...slippageLocal, custom: "" });

  const isDisabled = useMemo(
    () => currentSlippageLocal === SlippageLevels.custom && slippageLocal.custom === "",
    [currentSlippageLocal, slippageLocal.custom]
  );

  const handleSaveSwapSettings = () => {
    dispatch(
      saveSwapSettings({
        slippage: slippageLocal,
        currentSlippage: currentSlippageLocal,
      })
    );
    dispatch(setIsSettingsOpen(false));
    CachedService.OpenPositionClass.updateQouteAmounts();
  };

  const handleReset = () => {
    resetToDefaulLocalChanges();
    dispatch(resetSwapSettings());
  };

  return (
    <dialog
      id="my_modal_2"
      className="modal"
      style={isSettingsOpen ? { backdropFilter: "blur(10px)" } : {}}
      open={isSettingsOpen}
      onClose={closeSettingModal}
    >
      <div className="modal-box gradient-border !p-[0.125rem] !rounded-[0.25rem]">
        <div className="bg-base-content !rounded-[0.25rem] p-6">
          <div className="flex justify-between items-center ">
            <img
              src={backIcon}
              className="cursor-pointer accent-on-hover"
              alt="backIcon"
              style={{ transform: "rotate(90deg)", width: "20px" }}
              onClick={closeSettingModal}
            />
            <div className="text-xl font-semibold text-primary">Swap settings</div>
            <p className="text-primary cursor-pointer accent-on-hover" onClick={handleReset}>
              Reset
            </p>
          </div>
          <div className="flex flex-col gap-7 mt-8">
            <SlippageSelection
              slippage={slippageLocal}
              currentSlippage={currentSlippageLocal}
              onSlippageClick={handleSlippageClick}
              onCustomSlippageChange={handleCustomSlippageChange}
            />
          </div>
          <div
            onClick={handleSaveSwapSettings}
            className={`btn w-full text-primary mesh-gradient text-center font-bold py-3.5 mt-10 rounded button-hover-swap ${
              isDisabled ? "cursor-not-allowed opacity-30" : "cursor-pointer opacity-100"
            }`}
          >
            Save Settings
          </div>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};

export default SettingsModal;
