import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NATIVE_SOL_TOKENINFO, USDC_TOKENINFO } from "Constants/tokens";
import {
  ManageLiqduidityType,
  LiquidityPositionStatus,
  PercentageRanges,
  PoolData,
  UserPositionInfo,
} from "Types/pools";
import { openReducer } from "Types/reducers";
import { TokenInfo, TokenInputType } from "Types/tokens";
import { convertToJupiterTokenFormat } from "Utils/format";
import {
  calculateMinMaxPercentages,
  calculateMinMaxPricesRange,
  updateLiquidityPositionStatus,
} from "Utils/pools";

const initialState: openReducer = {
  tokenA: NATIVE_SOL_TOKENINFO,
  tokenB: USDC_TOKENINFO,
  amountA: "",
  amountB: "",
  currentPool: undefined,
  inputType: TokenInputType.A,
  maxPriceRange: 0,
  minPriceRange: 0,
  currentPoolPrice: 0,
  selectedMinPercentageRange: PercentageRanges._5,
  selectedMaxPercentageRange: PercentageRanges._5,
  errors: [],
  liquidityPositionStatus: LiquidityPositionStatus.in_price,
  manageLiquidityType: ManageLiqduidityType.Open,
  currentPosition: undefined,
};

const openSlice = createSlice({
  name: "open",
  initialState,
  reducers: {
    setTokenA(state, action: PayloadAction<TokenInfo>) {
      state.tokenA = action.payload;
    },
    setTokenB(state, action: PayloadAction<TokenInfo>) {
      state.tokenB = action.payload;
    },
    setManageLiquidityType(state, action: PayloadAction<ManageLiqduidityType>) {
      state.manageLiquidityType = action.payload;
    },
    setCurrentPosition(state, action: PayloadAction<UserPositionInfo>) {
      state.currentPosition = action.payload;
    },
    setMinPriceRange(state, action: PayloadAction<number>) {
      state.minPriceRange = action.payload;

      // update the price percentages w.r.t updated price ranges
      const { minPercentage, maxPercentage } = calculateMinMaxPercentages(
        state.currentPoolPrice,
        state.minPriceRange,
        action.payload
      );
      state.selectedMinPercentageRange = minPercentage;
      state.selectedMaxPercentageRange = maxPercentage;

      // update for the liquidity position
      const liquidityPositionStatus = updateLiquidityPositionStatus(
        state.liquidityPositionStatus,
        state.currentPoolPrice,
        action.payload,
        state.maxPriceRange
      );
      if (liquidityPositionStatus) {
        state.liquidityPositionStatus = liquidityPositionStatus;
      }
    },
    setMaxPriceRange(state, action: PayloadAction<number>) {
      state.maxPriceRange = action.payload;

      // update the price percentages w.r.t updated price ranges
      const { minPercentage, maxPercentage } = calculateMinMaxPercentages(
        state.currentPoolPrice,
        state.minPriceRange,
        action.payload
      );
      state.selectedMinPercentageRange = minPercentage;
      state.selectedMaxPercentageRange = maxPercentage;

      // update the liquidity position
      const liquidityPositionStatus = updateLiquidityPositionStatus(
        state.liquidityPositionStatus,
        state.currentPoolPrice,
        state.minPriceRange,
        action.payload
      );
      if (liquidityPositionStatus) {
        state.liquidityPositionStatus = liquidityPositionStatus;
      }
    },
    setAmountA(state, action: PayloadAction<string>) {
      if (state.inputType !== TokenInputType.A) {
        state.inputType = TokenInputType.A;
      }
      state.amountA = action.payload;
    },
    setAmountB(state, action: PayloadAction<string>) {
      if (state.inputType !== TokenInputType.B) {
        state.inputType = TokenInputType.B;
      }
      state.amountB = action.payload;
    },
    updateTokenAmountsAfterQoute(state, action: PayloadAction<{ A?: string; B?: string }>) {
      if (action.payload.A !== undefined) {
        state.amountA = action.payload.A;
      }
      if (action.payload.B !== undefined) {
        state.amountB = action.payload.B;
      }
    },
    setCurrentPool(
      state,
      action: PayloadAction<{
        poolData: PoolData | undefined;
        type: ManageLiqduidityType;
        positionData?: UserPositionInfo;
      }>
    ) {
      if (action.payload.poolData) {
        if (action.payload.type !== state.manageLiquidityType) {
          state.manageLiquidityType = action.payload.type;
        }
        state.currentPool = action.payload.poolData;
        state.tokenA = convertToJupiterTokenFormat(action.payload.poolData.tokenA);
        state.tokenB = convertToJupiterTokenFormat(action.payload.poolData.tokenB);
        if (action.payload.type === ManageLiqduidityType.Open) {
          state.currentPoolPrice = action.payload.poolData.price;
          const { min, max } = calculateMinMaxPricesRange(
            action.payload.poolData.price,
            state.selectedMinPercentageRange,
            state.selectedMaxPercentageRange
          );
          state.minPriceRange = min;
          state.maxPriceRange = max;
        } else if (
          action.payload.type === ManageLiqduidityType.Increase &&
          action.payload.positionData
        ) {
          state.currentPoolPrice = +action.payload.positionData.currentPrice;
          openSlice.caseReducers.setMaxPriceRange(state, {
            payload: +action.payload.positionData.priceUpper,
            type: "setMaxPriceRange",
          });
          openSlice.caseReducers.setMinPriceRange(state, {
            payload: +action.payload.positionData.priceLower,
            type: "setMinPriceRange",
          });
        }
      } else {
        return initialState;
      }
    },
    setCurrentPoolPrice(
      state,
      action: PayloadAction<{ price: number; type: ManageLiqduidityType }>
    ) {
      state.currentPoolPrice = action.payload.price;
      if (action.payload.type === ManageLiqduidityType.Open) {
        const { min, max } = calculateMinMaxPricesRange(
          action.payload.price,
          state.selectedMinPercentageRange,
          state.selectedMaxPercentageRange
        );
        state.minPriceRange = min;
        state.maxPriceRange = max;
      }
    },
    setSelectedPercentageRange(state, action: PayloadAction<{ min?: number; max?: number }>) {
      // update the percentages
      if (action.payload.min) {
        state.selectedMinPercentageRange = action.payload.min;
      }
      if (action.payload.max) {
        state.selectedMaxPercentageRange = action.payload.max;
      }

      // update the price ranges w.r.t updated percentages
      const { min, max } = calculateMinMaxPricesRange(
        state.currentPoolPrice,
        action.payload?.min ?? state.selectedMinPercentageRange,
        action.payload?.max ?? state.selectedMaxPercentageRange
      );
      state.minPriceRange = min;
      state.maxPriceRange = max;

      // update the liquidity position
      const liquidityPositionStatus = updateLiquidityPositionStatus(
        state.liquidityPositionStatus,
        state.currentPoolPrice,
        min,
        max
      );
      if (liquidityPositionStatus) {
        state.liquidityPositionStatus = liquidityPositionStatus;
      }
    },
    setErrors(state, action: PayloadAction<string[]>) {
      state.errors = action.payload;
    },
    setLiquidityPosition(state, action: PayloadAction<LiquidityPositionStatus>) {
      state.liquidityPositionStatus = action.payload;
    },
  },
});

export default openSlice.reducer;

export const {
  setManageLiquidityType,
  setTokenA,
  setTokenB,
  setAmountA,
  setAmountB,
  setCurrentPool,
  setMinPriceRange,
  setMaxPriceRange,
  updateTokenAmountsAfterQoute,
  setCurrentPoolPrice,
  setSelectedPercentageRange,
  setErrors,
  setLiquidityPosition,
  setCurrentPosition,
} = openSlice.actions;
