import { useMemo } from "react";
import { area, curveStepAfter, curveStepBefore, ScaleLinear } from "d3";

import { ChartEntry } from "./types";

export const Path = ({
  opacity,
  fill,
  d,
}: {
  opacity: string | undefined;
  fill: string | undefined;
  d: any;
}) => {
  const defaultOpacity = 0.9;
  const defaultFill = "#2B6AFF";

  const pathStyle = {
    opacity: opacity || defaultOpacity,
    stroke: fill || defaultFill,
    fill: fill || defaultFill,
  };

  return (
    <svg width="100" height="100">
      <path d="M10 10 H 90 V 90 H 10 L 10 10" style={pathStyle} />
    </svg>
  );
};

export const Area = ({
  series,
  xScale,
  yScale,
  xValue,
  yValue,
  fill,
  opacity,
  curveAfter = true,
}: {
  series: ChartEntry[];
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  xValue: (d: ChartEntry) => number;
  yValue: (d: ChartEntry) => number;
  fill?: string | undefined;
  opacity?: string;
  curveAfter?: boolean;
}) =>
  useMemo(
    () => (
      <Path
        opacity={opacity}
        fill={fill}
        d={
          area()
            .curve(curveAfter ? curveStepAfter : curveStepBefore)
            .x((d: unknown) => xScale(xValue(d as ChartEntry)))
            .y1((d: unknown) => yScale(yValue(d as ChartEntry)))
            .y0(yScale(0))(
            series.filter((d) => {
              const value = xScale(xValue(d));
              return value > 0 && value <= window.innerWidth;
            }) as Iterable<[number, number]>
          ) ?? undefined
        }
      />
    ),
    [fill, series, xScale, xValue, yScale, yValue, curveAfter]
  );
