import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { AMOUNT_INPUT_REGEX } from "Constants/misc";
import { calculateInputWidth, validateDecimalPlaces } from "Utils/judger";
import { Balance } from "./balance";
import { PercentageGroup } from "./percentageGroup";
import { TokenInfo, TokenInputType } from "Types/tokens";

type Props = {
  amount: string;
  setAmount: (text: string) => void;
  subHeading: string;
  showPercentage?: boolean;
  id: string;
  token: TokenInfo;
  ontokenClick: () => void;
  loading: boolean;
  priceLoading: boolean;
  isInSufficientBalance?: boolean;
  editable?: boolean;
  tokenType: TokenInputType;
};

export const Input = ({
  amount,
  setAmount,
  subHeading,
  showPercentage = false,
  id,
  token,
  ontokenClick,
  loading,
  priceLoading,
  isInSufficientBalance = false,
  editable = true,
  tokenType,
}: Props) => {
  const [inputWidth, setInputWidth] = useState(1.5);
  const [inputMaxWidth, setInputMaxWidth] = useState(120);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (Number(amount)) {
      setInputWidth(calculateInputWidth(amount));
    }
  }, [amount]);

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    if (editable) {
      if (text.match(AMOUNT_INPUT_REGEX)) {
        if (validateDecimalPlaces(text, token.decimals)) {
          setInputWidth(calculateInputWidth(text));
          setAmount(text);
        }
      } else {
        resetInput();
      }
    }
  };

  const resetInput = () => {
    setAmount("");
    setInputWidth(1.5);
  };

  useEffect(() => {
    if (divRef.current) setInputMaxWidth(divRef.current.offsetWidth - 14);
  }, [token.symbol]);

  return (
    <>
      <div className="flex items-center justify-between mb-1">
        <p className="text-secondary font-semibold text-xs sm:text-sm">{subHeading}</p>
        {showPercentage && <PercentageGroup decimals={token.decimals} tokenType={tokenType} />}
      </div>
      <div
        className="flex justify-between items-center cursor-text"
        onClick={() => (document.getElementById(id) as HTMLInputElement).focus()}
      >
        {loading ? (
          <div style={{ width: "40%" }}>
            <Skeleton
              baseColor="#cd42ff"
              highlightColor="#8fb9fc"
              width="100%"
              height="25px"
              style={{ opacity: 0.5 }}
            />
          </div>
        ) : (
          <div
            className="flex items-center"
            style={{
              maxWidth: `calc(100% - ${inputMaxWidth}px)`,
            }}
          >
            <input
              autoComplete="off"
              id={id}
              value={amount}
              type="text"
              onChange={onValueChange}
              className={`input px-0 max-w-[100%] sm:max-w-[calc(100% - 40px)] text-3xl sm:text-4xl ${
                isInSufficientBalance ? "text-red-500" : "text-white"
              } `}
              placeholder="0"
              style={{
                width: `${inputWidth}ch`,
              }}
              onFocus={() => {
                (document.getElementById(`${id}-container`) as HTMLDivElement).style.boxShadow =
                  "0px 0px 14px 5px rgba(205, 66, 255, 0.2)";
              }}
              onBlur={() => {
                (document.getElementById(`${id}-container`) as HTMLDivElement).style.boxShadow =
                  "0px 0px 14px 5px rgba(205, 66, 255, 0)";
              }}
            />
          </div>
        )}
        <div
          ref={divRef}
          id={`${id}-button`}
          tabIndex={0}
          className="btn bg-transparent text-primary flex items-center justify-end w-max mr-[-1rem] rounded border-none"
          onClick={(e) => {
            e.stopPropagation();
            ontokenClick();
          }}
        >
          <img src={token.logoURI} alt="token" className="w-6 rounded-full" /> <p>{token.symbol}</p>
        </div>
      </div>
      <Balance priceLoading={priceLoading || loading} token={token} amount={amount} />
    </>
  );
};
