export const NATIVE_SOL = "So11111111111111111111111111111111111111112";
export const MESH_TOTAL_SUPPLY = 30000000; //11031000

export const TELEGRAM_LINK = "https://t.me/meshprotocol";
export const X_HANDLE = "https://twitter.com/mesh_protocol";
export const EMAIL = "contact@meshprotocol.xyz";
export const GITBOOK = "https://docs.meshprotocol.xyz/";
export const MESH_WEBSITE = "https://meshprotocol.xyz";
export const AMOUNT_INPUT_REGEX = /^((\d+[.]?\d*)|(\.\d+))$/;

export const WALLET_BTN_LABELS = {
  "change-wallet": "Change Wallet",
  connecting: "Connecting ...",
  "copy-address": "Copy Address",
  copied: "Copied",
  disconnect: "Disconnect",
  "has-wallet": "Connect",
  "no-wallet": "Connect Wallet",
} as const;

export const solanaExplorerUrl = "https://explorer.solana.com/";
export const RADIYUM_URL =
  "https://raydium.io/swap/?inputCurrency=sol&outputCurrency=MESHwqmXvAmKpDYSgRZkm9D5H8xYSCVixeyZoePHn4G&fixed=in";
export const MESH_SWAP_URL = "https://swap.meshprotocol.xyz";
export const MESH_STAKING_URL = "https://www.staking.meshprotocol.xyz";
export const MESH_LIQUIDITY_URL = "https://app.meshprotocol.xyz";
export const MESH_ANALYTICS_URL = "https://dune.com/luffythedev/mesh-protocol-token";
export const POOLSLIST_EXPIRE_PERIOD = 1 * 60 * 60 * 1000;
export const TOKENLIST_EXPIRE_PERIOD = 24 * 60 * 60 * 1000;
export const minimumSOLbalance = 0.00293516;
export const DEPLOYMENT_TIMESTAMP_KEY = "deploymentTimestamp";
export const DEPLOYMENT_TIMESTAMP = process.env.REACT_APP_DEPLOYMENT_TIMESTAMP;
export const navbarLinks = [
  { name: "Liquidity", url: MESH_LIQUIDITY_URL },
  { name: "Swap", url: MESH_SWAP_URL },
  { name: "Stake", url: MESH_STAKING_URL },
];
