import { useEffect } from "react";

import { useSelector } from "Store";
import LiquidtyAdder from "./Components/liquidtyAdder";
import CachedService from "Classes/cachedService";
import Listeners from "Pages/OpenPosition/Components/listeners";
import PoolInfo from "./Components/poolInfo";
import RangeSelector from "./Components/rangeSelector";

const AddLiquidity = () => {
  const currentPool = useSelector((state) => state.open.currentPool);

  useEffect(() => {
    if (!currentPool) {
      CachedService.navigation(-1);
    }
  }, [currentPool]);

  return currentPool ? (
    <>
      <Listeners />
      <div>
        <div className="flex flex-col items-center mt-4 md:mt-0 justify-center">
          <div className="grid grid-cols-12 w-[93vw] max-w-[1400px] gap-5">
            <div className="flex items-center m-4">
              <span
                className="text-gray-500 mt-3 cursor-pointer hover:text-white"
                onClick={() => CachedService.navigation(-1)}
              >
                {"<- Back"}
              </span>
            </div>
            <div className="col-span-12 bg-base-content rounded">
              <PoolInfo currentPool={currentPool} />
            </div>
            <div className="col-span-12 grid grid-cols-12 gap-5">
              <div className="col-span-12 lg:col-span-7">
                <div className="h-full bg-base-content rounded">
                  <RangeSelector />
                </div>
              </div>
              <div className="col-span-12 lg:col-span-5 hidden lg:block rounded">
                <LiquidtyAdder />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default AddLiquidity;
