import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserPositionInfo } from "Types/pools";
import { PortfolioReducer } from "Types/reducers";

const initialState: PortfolioReducer = {
  positions: [],
};

const portfolio = createSlice({
  name: "portfolio",
  initialState,
  reducers: {
    setUserAllPositions(state, action: PayloadAction<UserPositionInfo[]>) {
      state.positions = action.payload;
    },
  },
});

export default portfolio.reducer;

export const { setUserAllPositions } = portfolio.actions;
