import { IncreaseLiquidityQuote as OrcaQoute, Whirlpool } from "@orca-so/whirlpools-sdk";
import {
  ApiV3PoolInfoConcentratedItem,
  ReturnTypeGetLiquidityAmountOut as RaydiumQoute,
} from "@raydium-io/raydium-sdk-v2";

import { OrcaOpenPosition, RaydiumOpenPosition } from "Classes/OpenPosition/concrete";
import { DexType } from "Types/pools";

export class OpenPositionFactory {
  static chooseSDK(sdk: DexType) {
    switch (sdk) {
      case DexType.RAYDIUM:
        return new RaydiumOpenPosition<RaydiumQoute, ApiV3PoolInfoConcentratedItem>();
      case DexType.ORCA:
        return new OrcaOpenPosition<OrcaQoute, Whirlpool>();
      // Add other SDK cases here
      default:
        throw new Error("Unsupported SDK");
    }
  }
}
