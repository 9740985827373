import { useMemo } from "react";
import { useAnchorWallet, useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useWalletMultiButton } from "@solana/wallet-adapter-base-ui";

import { useWalletModal } from "Components/walletModal";
import { useSelector } from "Store";
import { OrcaManager } from "Classes/CoreFunctionalities/orcaManager";
import { RaydiumManager } from "Classes/CoreFunctionalities/raydiumManager";
import { ManageLiqduidityType, DexType } from "Types/pools";

const ActionBtn = () => {
  const wallet = useWallet();
  const anchorWallet = useAnchorWallet();
  const { connection } = useConnection();
  const { setVisible: setModalVisible } = useWalletModal();

  const amountA = useSelector((state) => state.open.amountA);
  const currentPool = useSelector((state) => state.open.currentPool);
  const amountB = useSelector((state) => state.open.amountB);
  const tokenA = useSelector((state) => state.open.tokenA);
  const tokenB = useSelector((state) => state.open.tokenB);
  const manageLiquidityType = useSelector((state) => state.open.manageLiquidityType);
  const isTxInProgress = useSelector((state) => state.loadings.isTxInProgress);
  const isApprovalPending = useSelector((state) => state.loadings.isApprovalPending);
  const isInSufficientBalanceA = useSelector((state) => state.session.isInSufficientBalanceA);
  const isInSufficientBalanceB = useSelector((state) => state.session.isInSufficientBalanceB);

  const isDisabled = useMemo(
    () =>
      isTxInProgress ||
      isInSufficientBalanceA ||
      isInSufficientBalanceB ||
      (+amountA === 0 && +amountB === 0),
    [isTxInProgress, isInSufficientBalanceA, isInSufficientBalanceB, amountA, amountB]
  );

  const { buttonState, onConnect } = useWalletMultiButton({
    onSelectWallet() {
      setModalVisible(true);
    },
  });

  const handleClick = async () => {
    if (wallet.connected && !isDisabled && anchorWallet && currentPool) {
      const dexManager =
        currentPool.dexType === DexType.ORCA
          ? new OrcaManager(anchorWallet, connection)
          : new RaydiumManager(anchorWallet, connection);

      if (manageLiquidityType === ManageLiqduidityType.Open) {
        await dexManager.openPosition();
      } else if (manageLiquidityType === ManageLiqduidityType.Increase) {
        await dexManager.increaseLiquidity();
      }
    } else {
      switch (buttonState) {
        case "no-wallet":
          setModalVisible(true);
          break;
        case "has-wallet":
          if (onConnect) {
            onConnect();
          }
          break;
      }
    }
  };

  const BtnText = useMemo(
    () =>
      !wallet.connected
        ? "Connect Wallet"
        : !amountA && !amountB
        ? "Enter an amount"
        : isInSufficientBalanceA
        ? `Insufficient ${tokenA.symbol}`
        : isInSufficientBalanceB
        ? `Insufficient ${tokenB.symbol}`
        : isApprovalPending
        ? "Approval Pending"
        : isTxInProgress
        ? "Processing"
        : manageLiquidityType === ManageLiqduidityType.Increase
        ? "Increase Liquidity"
        : "Deposit",
    [
      wallet.connected,
      amountA,
      amountB,
      isInSufficientBalanceA,
      tokenA.symbol,
      isInSufficientBalanceB,
      tokenB.symbol,
      isApprovalPending,
      isTxInProgress,
      manageLiquidityType,
    ]
  );

  return (
    <div
      onClick={handleClick}
      className={`text-primary text-lg mesh-gradient text-center font-extrabold py-3.5 mt-5 rounded button-hover-swap ${
        wallet.connected && isDisabled
          ? "cursor-not-allowed opacity-30"
          : "cursor-pointer opacity-100"
      }`}
    >
      {BtnText}
    </div>
  );
};

export default ActionBtn;
