import {
  IGNORE_CACHE,
  ORCA_WHIRLPOOLS_CONFIG,
  ORCA_WHIRLPOOL_PROGRAM_ID,
  PDAUtil,
  PriceMath,
  TickUtil,
  WhirlpoolClient,
} from "@orca-so/whirlpools-sdk";
import { PublicKey } from "@solana/web3.js";
import { store } from "Store";
import { setIsPoolInfoLoading } from "Store/Reducers/loadings";
import Decimal from "decimal.js";

export const getPoolInfoFromPoolAddress = async (poolAddress: string, client: WhirlpoolClient) => {
  store.dispatch(setIsPoolInfoLoading(true));
  const pool = await client.getPool(new PublicKey(poolAddress), IGNORE_CACHE);
  const poolData = pool.getData();
  const poolTokenAInfo = pool.getTokenAInfo();
  const poolTokenBInfo = pool.getTokenBInfo();
  store.dispatch(setIsPoolInfoLoading(false));

  return { pool, poolData, poolTokenAInfo, poolTokenBInfo, poolAddress };
};

export const getPoolInfoFromTokens = (
  tokenA: string,
  tokenB: string,
  tickSpacing: number,
  client: WhirlpoolClient
) => {
  const poolAddress = PDAUtil.getWhirlpool(
    ORCA_WHIRLPOOL_PROGRAM_ID,
    ORCA_WHIRLPOOLS_CONFIG,
    new PublicKey(tokenA),
    new PublicKey(tokenB),
    tickSpacing
  );
  return getPoolInfoFromPoolAddress(poolAddress.publicKey.toBase58(), client);
};

export const getPoolInfoFromPositionToken = async (
  positionToken: string,
  client: WhirlpoolClient
) => {
  const position_publickey = PDAUtil.getPosition(
    ORCA_WHIRLPOOL_PROGRAM_ID,
    new PublicKey(positionToken)
  ).publicKey;
  const position = await client.getPosition(position_publickey, IGNORE_CACHE);
  const position_data = position.getData();
  const whirlpool = await client.getPool(position_data.whirlpool);
  const poolInfo = await getPoolInfoFromPoolAddress(whirlpool.getAddress().toBase58(), client);
  return {
    ...poolInfo,
    position,
    position_data,
  };
};

export const getTickIndexFromTokenPrice = (
  price: number,
  decimalsTokenA: number,
  decimalTokenB: number,
  tickSpacing: number
) => {
  return TickUtil.getInitializableTickIndex(
    PriceMath.priceToTickIndex(new Decimal(price), decimalsTokenA, decimalTokenB),
    tickSpacing
  );
};
