import { toast } from "react-toastify";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import { Connection, PublicKey, Signer, VersionedTransaction } from "@solana/web3.js";
import {
  IGNORE_CACHE,
  IncreaseLiquidityQuote,
  ORCA_WHIRLPOOL_PROGRAM_ID,
  PDAUtil,
  PoolUtil,
  Position,
  TickUtil,
  Whirlpool,
  WhirlpoolIx,
  decreaseLiquidityQuoteByLiquidityWithParams,
} from "@orca-so/whirlpools-sdk";
import Decimal from "decimal.js";
import {
  EMPTY_INSTRUCTION,
  Instruction,
  Percentage,
  Percentage as PercentageOrca,
  resolveOrCreateATA,
  TransactionBuilder,
} from "@orca-so/common-sdk";
import { BN } from "@coral-xyz/anchor";

import { transactionSenderAndConfirmationWaiter } from "Utils/txSender";
import { getPoolInfoFromPositionToken, getTickIndexFromTokenPrice } from "Utils/orca/fetcher";
import { store } from "Store";
import { setIsApprovalPending, setIsTxInProgress } from "Store/Reducers/loadings";
import {
  DepositSuccessToast,
  TxCanceledToast,
  TxFailedToast,
  TxProgressToast,
  TxSignToast,
  WithdrawSuccessToast,
} from "Components/toasts";
import { incrementSuccessTxCount, resetPercentages } from "Store/Reducers/session";
import { BaseLiquidityManager, TxFailChore } from "Classes/CoreFunctionalities/interface";
import CachedService from "../cachedService";
import { getAssociatedTokenAddressSync } from "@solana/spl-token";

export class OrcaManager extends BaseLiquidityManager {
  protected wallet: AnchorWallet;
  protected connection: Connection;
  ctx = CachedService.WhirlpoolContext;
  client = CachedService.WhirlpoolClient;
  fetcher = CachedService.WhirlpoolFetcher;

  constructor(wallet: AnchorWallet, connection: Connection) {
    super();
    this.wallet = wallet;
    this.connection = connection;
  }

  async openPosition(): Promise<void> {
    if (this.ctx && this.client && this.fetcher && this.wallet) {
      try {
        store.dispatch(setIsTxInProgress(true));
        const { open } = store.getState();
        const { maxPriceRange, minPriceRange } = open;
        const pool = CachedService.OpenPositionClass.pool as Whirlpool;
        const qoute = CachedService.OpenPositionClass.qoute as IncreaseLiquidityQuote;
        const poolData = pool.getData();
        const poolTokenAInfo = pool.getTokenAInfo();
        const poolTokenBInfo = pool.getTokenBInfo();
        const poolAddress = pool.getAddress().toString();

        const tickLowerIndex = getTickIndexFromTokenPrice(
          minPriceRange,
          poolTokenAInfo.decimals,
          poolTokenBInfo.decimals,
          poolData.tickSpacing
        );
        const tickUpperIndex = getTickIndexFromTokenPrice(
          maxPriceRange,
          poolTokenAInfo.decimals,
          poolTokenBInfo.decimals,
          poolData.tickSpacing
        );

        const tickArray = PDAUtil.getTickArrayFromTickIndex(
          tickLowerIndex,
          poolData.tickSpacing,
          new PublicKey(poolAddress),
          this.ctx.program.programId
        );
        const ta = await this.fetcher.getTickArray(tickArray.publicKey);

        const { positionMint, tx } = await pool.openPositionWithMetadata(
          tickLowerIndex,
          tickUpperIndex,
          qoute
        );

        if (!Boolean(ta)) {
          console.log("tick array does not exist");
          const startTickIndex_lower = TickUtil.getStartTickIndex(
            tickLowerIndex,
            poolData.tickSpacing
          );
          const startTickIndex_upper = TickUtil.getStartTickIndex(
            tickUpperIndex,
            poolData.tickSpacing
          );
          const txBuilder = await pool.initTickArrayForTicks(
            [startTickIndex_lower, startTickIndex_upper],
            this.wallet.publicKey
          );
          if (txBuilder) {
            const ix = txBuilder?.compressIx(true);
            tx.prependInstruction(ix);
          }
        }

        const build = await tx.build({ computeBudgetOption: { type: "auto" } });
        const txId = await this.executeTransaction(
          build.transaction as VersionedTransaction,
          build.signers
        );
        store.dispatch(setIsTxInProgress(false));
        console.log("txId", txId);
      } catch (error: any) {
        console.log("error", error.message);
        TxFailChore(<TxFailedToast />);
        store.dispatch(setIsTxInProgress(false));
      }
    }
  }

  async closePosition(): Promise<void> {
    try {
      store.dispatch(setIsTxInProgress(true));
      const ctx = CachedService.WhirlpoolContext;
      const client = CachedService.WhirlpoolClient;
      const fetcher = CachedService.WhirlpoolFetcher;
      const { open, app } = store.getState();
      const { currentPosition } = open;
      const { slippage, currentSlippage } = app;
      if (ctx && client && fetcher && this.wallet && currentPosition) {
        const {
          tickSpacing,
          positionAddress,
          infoTokenA,
          infoTokenB,
          poolAddress,
          positionNFTaddress,
          tickLower,
          tickUpper,
          liquidity,
        } = currentPosition;

        const position_pubkey = new PublicKey(positionAddress);
        const position_owner = this.wallet.publicKey;
        const token_a = infoTokenA;
        const token_b = infoTokenB;
        const whirlpool_pubkey = new PublicKey(poolAddress);
        const position_token_account = getAssociatedTokenAddressSync(
          new PublicKey(positionNFTaddress),
          position_owner
        );
        // Get the pool to which the position belongs
        const whirlpool = await client.getPool(whirlpool_pubkey);
        const whirlpool_data = whirlpool.getData();

        const tick_array_lower_pubkey = PDAUtil.getTickArrayFromTickIndex(
          tickLower,
          tickSpacing,
          whirlpool_pubkey,
          ctx.program.programId
        ).publicKey;
        const tick_array_upper_pubkey = PDAUtil.getTickArrayFromTickIndex(
          tickUpper,
          tickSpacing,
          whirlpool_pubkey,
          ctx.program.programId
        ).publicKey;
        // Create token accounts to receive fees and rewards
        // Collect mint addresses of tokens to receive
        const tokens_to_be_collected = new Set<string>();
        tokens_to_be_collected.add(token_a.mint);
        tokens_to_be_collected.add(token_b.mint);
        whirlpool_data.rewardInfos.forEach((reward_info) => {
          if (PoolUtil.isRewardInitialized(reward_info)) {
            tokens_to_be_collected.add(reward_info.mint.toBase58());
          }
        });
        // Get addresses of token accounts and get instructions to create if it does not exist
        const required_ta_ix: Instruction[] = [];
        const token_account_map = new Map<string, PublicKey>();
        for (let mint_b58 of tokens_to_be_collected) {
          const mint = new PublicKey(mint_b58);
          // If present, ix is EMPTY_INSTRUCTION
          const { address, ...ix } = await resolveOrCreateATA(
            ctx.connection,
            position_owner,
            mint,
            () => fetcher.getAccountRentExempt()
          );
          required_ta_ix.push(ix);
          token_account_map.set(mint_b58, address);
        }
        // Build the instruction to update fees and rewards
        let update_fee_and_rewards_ix = WhirlpoolIx.updateFeesAndRewardsIx(ctx.program, {
          whirlpool: whirlpool_pubkey,
          position: position_pubkey,
          tickArrayLower: tick_array_lower_pubkey,
          tickArrayUpper: tick_array_upper_pubkey,
        });
        // Build the instruction to collect fees
        let collect_fees_ix = WhirlpoolIx.collectFeesIx(ctx.program, {
          whirlpool: whirlpool_pubkey,
          position: position_pubkey,
          positionAuthority: position_owner,
          positionTokenAccount: position_token_account,
          tokenOwnerAccountA: token_account_map.get(token_a.mint) as PublicKey,
          tokenOwnerAccountB: token_account_map.get(token_b.mint) as PublicKey,
          tokenVaultA: whirlpool_data.tokenVaultA,
          tokenVaultB: whirlpool_data.tokenVaultB,
        });
        // Build the instructions to collect rewards
        const collect_reward_ix = [EMPTY_INSTRUCTION, EMPTY_INSTRUCTION, EMPTY_INSTRUCTION];
        for (let i = 0; i < whirlpool_data.rewardInfos.length; i++) {
          const reward_info = whirlpool_data.rewardInfos[i];
          if (!PoolUtil.isRewardInitialized(reward_info)) continue;
          collect_reward_ix[i] = WhirlpoolIx.collectRewardIx(ctx.program, {
            whirlpool: whirlpool_pubkey,
            position: position_pubkey,
            positionAuthority: position_owner,
            positionTokenAccount: position_token_account,
            rewardIndex: i,
            rewardOwnerAccount: token_account_map.get(reward_info.mint.toBase58()) as PublicKey,
            rewardVault: reward_info.vault,
          });
        }
        // Estimate the amount of tokens that can be withdrawn from the position
        const quote = decreaseLiquidityQuoteByLiquidityWithParams({
          // Pass the pool state as is
          sqrtPrice: whirlpool_data.sqrtPrice,
          tickCurrentIndex: whirlpool_data.tickCurrentIndex,
          // Pass the price range of the position as is
          tickLowerIndex: tickLower,
          tickUpperIndex: tickUpper,
          // Liquidity to be withdrawn (All liquidity)
          liquidity: new BN(liquidity),
          // Acceptable slippage
          slippageTolerance: Percentage.fromDecimal(new Decimal(slippage[currentSlippage])),
        });

        // Build the instruction to decrease liquidity
        const decrease_liquidity_ix = WhirlpoolIx.decreaseLiquidityIx(ctx.program, {
          ...quote,
          whirlpool: whirlpool_pubkey,
          position: position_pubkey,
          positionAuthority: position_owner,
          positionTokenAccount: position_token_account,
          tokenOwnerAccountA: token_account_map.get(token_a.mint) as PublicKey,
          tokenOwnerAccountB: token_account_map.get(token_b.mint) as PublicKey,
          tokenVaultA: whirlpool_data.tokenVaultA,
          tokenVaultB: whirlpool_data.tokenVaultB,
          tickArrayLower: tick_array_lower_pubkey,
          tickArrayUpper: tick_array_upper_pubkey,
        });
        // Build the instruction to close the position
        const close_position_ix = WhirlpoolIx.closePositionIx(ctx.program, {
          position: position_pubkey,
          positionAuthority: position_owner,
          positionTokenAccount: position_token_account,
          positionMint: new PublicKey(positionNFTaddress),
          receiver: position_owner,
        });
        // Create a transaction and add the instruction
        const tx_builder = new TransactionBuilder(ctx.connection, ctx.wallet);
        // Create token accounts
        required_ta_ix.map((ix) => tx_builder.addInstruction(ix));
        tx_builder
          // Update fees and rewards, collect fees, and collect rewards
          .addInstruction(update_fee_and_rewards_ix)
          .addInstruction(collect_fees_ix)
          .addInstruction(collect_reward_ix[0])
          .addInstruction(collect_reward_ix[1])
          .addInstruction(collect_reward_ix[2])
          // Decrease liquidity
          .addInstruction(decrease_liquidity_ix)
          // Close the position
          .addInstruction(close_position_ix);
        // Send the transaction
        const build = await tx_builder.build({
          computeBudgetOption: { type: "auto" },
        });

        const txId = await this.executeTransaction(
          build.transaction as VersionedTransaction,
          build.signers,
          WithdrawSuccessToast
        );
        store.dispatch(setIsTxInProgress(false));
        console.log("txId", txId);
      }
    } catch (error) {
      console.log("Error Closing Position:", error);
      TxFailChore(<TxFailedToast />);
      store.dispatch(setIsTxInProgress(false));
    }
  }

  async increaseLiquidity(): Promise<void> {
    const { currentPosition } = store.getState().open;
    if (this.ctx && this.client && this.fetcher && currentPosition) {
      try {
        store.dispatch(setIsTxInProgress(true));

        const qoute = CachedService.OpenPositionClass.qoute as IncreaseLiquidityQuote;

        const position = await this.client.getPosition(
          new PublicKey(currentPosition.positionAddress),
          IGNORE_CACHE
        );

        // const quote = increaseLiquidityQuoteByInputTokenWithParamsUsingPriceSlippage({
        //   // Pass the pool definition and state
        //   tokenMintA: poolTokenAInfo.mint,
        //   tokenMintB: poolTokenBInfo.mint,
        //   sqrtPrice: poolData.sqrtPrice,
        //   tickCurrentIndex: poolData.tickCurrentIndex,
        //   // Pass the price range of the position as is
        //   tickLowerIndex: position_data.tickLowerIndex,
        //   tickUpperIndex: position_data.tickUpperIndex,
        //   // Input token and amount
        //   inputTokenMint: poolTokenAInfo.mint,
        //   inputTokenAmount: DecimalUtil.toBN(new Decimal(amount), poolTokenAInfo.decimals),
        //   // Acceptable slippage
        //   slippageTolerance: slippage,
        // });

        const increase_liquidity_tx = await position.increaseLiquidity(qoute);
        const build = await increase_liquidity_tx.build({
          computeBudgetOption: { type: "auto" },
        });
        const txId = await this.executeTransaction(
          build.transaction as VersionedTransaction,
          build.signers
        );
        console.log("txId", txId);
        store.dispatch(setIsTxInProgress(false));
      } catch (error: any) {
        console.log("error", error.message);
        TxFailChore(<TxFailedToast />);
        store.dispatch(setIsTxInProgress(false));
      }
    }
  }

  async decreaseLiquidity(): Promise<void> {
    const positionToken = "HRB139vVujKKySz3dH1c8C9Vr3KJKFLYYkQAsCkQXdXp";
    const slippage = PercentageOrca.fromFraction(10, 1000); // 1%

    if (this.ctx && this.client && this.fetcher && this.wallet) {
      const { poolData, position_data, position } = await getPoolInfoFromPositionToken(
        positionToken,
        this.client
      );
      const liquidity_to_remove = position_data.liquidity.mul(new BN(30)).div(new BN(100));

      const quote = decreaseLiquidityQuoteByLiquidityWithParams({
        // Pass the pool state as is
        sqrtPrice: poolData.sqrtPrice,
        tickCurrentIndex: poolData.tickCurrentIndex,
        // Pass the price range of the position as is
        tickLowerIndex: position_data.tickLowerIndex,
        tickUpperIndex: position_data.tickUpperIndex,
        // Liquidity to be withdrawn
        liquidity: liquidity_to_remove,
        // Acceptable slippage
        slippageTolerance: slippage,
      });

      const decrease_liquidity_tx = await position.decreaseLiquidity(quote);

      const build = await decrease_liquidity_tx.build({
        computeBudgetOption: { type: "auto" },
      });
      const txId = await this.executeTransaction(
        build.transaction as VersionedTransaction,
        build.signers
      );
      console.log("txId", txId);
    }
  }

  async executeTransaction(
    transaction: VersionedTransaction,
    signers: Signer[],
    SuccessToast: ({ txId }: { txId: string }) => JSX.Element = DepositSuccessToast
  ) {
    const client = this.client;
    if (client) {
      CachedService.TxProgressToast(<TxSignToast />);
      store.dispatch(setIsApprovalPending(true));

      return await this.wallet
        .signTransaction(transaction)
        .then(async (signedTx) => {
          signedTx.sign(signers);
          toast.dismiss();
          CachedService.TxProgressToast(<TxProgressToast />);
          store.dispatch(setIsApprovalPending(false));
          const { blockhash, lastValidBlockHeight } = await this.connection.getLatestBlockhash();
          const transactionResponse = await transactionSenderAndConfirmationWaiter({
            connection: this.connection,
            serializedTransaction: signedTx.serialize(),
            blockhashWithExpiryBlockHeight: {
              blockhash,
              lastValidBlockHeight,
            },
          });

          // If we are not getting a response back, the transaction has not confirmed.
          if (!transactionResponse) {
            console.error("Transaction not confirmed", transactionResponse);
            TxFailChore(<TxFailedToast />);
            return;
          }

          if (transactionResponse.meta?.err) {
            console.error("transactionResponse in error", transactionResponse);
            TxFailChore(<TxFailedToast />);
            return;
          }

          if (transactionResponse) {
            const txId = transactionResponse.transaction.signatures[0];
            console.log("success transactionResponse", transactionResponse, txId);
            toast.dismiss();
            CachedService.successToast(<SuccessToast txId={txId} />);
            store.dispatch(incrementSuccessTxCount());
            store.dispatch(resetPercentages());
            return txId;
          }
        })
        .catch((err) => {
          console.log("sign transaction failed", err);
          store.dispatch(setIsApprovalPending(false));
          TxFailChore(<TxCanceledToast />);
        });
    }
  }

  async getPosition(positionMint: string) {
    let position: Position | undefined;
    try {
      if (this.client) {
        position = await this.client.getPosition(
          PDAUtil.getPosition(ORCA_WHIRLPOOL_PROGRAM_ID, new PublicKey(positionMint)).publicKey
        );
        console.log("position", (await position.refreshData()).liquidity.toString());
      }
    } catch (error) {
      console.log("unable to fetch position data of", positionMint);
    }
    return position;
  }
}
