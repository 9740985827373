import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import WalletIcon from "Assets/Svgs/wallet.svg";
import { exactAmountInDecimals, formatDollarAmount, formatTokenAmount } from "Utils/format";
import { Tooltip } from "./tooltip";
import { useSelector } from "Store";
import { TokenInfo } from "Types/tokens";

export const Balance = ({
  priceLoading,
  token,
  amount,
}: {
  priceLoading: boolean;
  token: TokenInfo;
  amount: string;
}) => {
  const balanceLoading = useSelector((state) => state.loadings.balanceLoading);
  const userTokens = useSelector((state) => state.session.userTokens);
  const currentPrices = useSelector((state) => state.session.currentPrices);

  const BalanceToShow = useMemo(
    () => userTokens[token.address]?.uiAmount ?? 0,
    [token.address, userTokens]
  );

  const usdPriceToShow = useMemo(() => {
    const tokenPrice = currentPrices[token.address];
    if (tokenPrice) {
      return tokenPrice.price;
    } else return undefined;
  }, [currentPrices, token.address]);

  return (
    <div className="text-xs flex items-center justify-between mt-2">
      {priceLoading ? (
        <Skeleton
          baseColor="#cd42ff"
          highlightColor="#8fb9fc"
          width="50px"
          style={{ opacity: 0.5 }}
        />
      ) : usdPriceToShow ? (
        <Tooltip text={"$" + (Number(amount) * usdPriceToShow).toFixed(4)}>
          <span className="text-secondary mb-0">
            ~{formatDollarAmount(Number(amount) * usdPriceToShow)}
          </span>
        </Tooltip>
      ) : (
        <div />
      )}
      <span className="flex ml-1 items-center">
        {balanceLoading ? (
          <Skeleton
            baseColor="#cd42ff"
            highlightColor="#8fb9fc"
            width="50px"
            style={{ opacity: 0.5 }}
          />
        ) : (
          <>
            <img src={WalletIcon} alt="WalletIcon" className="mb-1" />
            <Tooltip
              text={exactAmountInDecimals(BalanceToShow, token.decimals) + ` ${token.symbol}`}
            >
              <p className="font-bold ml-1 text-primary">{formatTokenAmount(BalanceToShow)}</p>
            </Tooltip>
            {priceLoading ? (
              <Skeleton
                baseColor="#cd42ff"
                highlightColor="#8fb9fc"
                width="40px"
                style={{ opacity: 0.5, marginLeft: 5 }}
              />
            ) : usdPriceToShow ? (
              <Tooltip text={"$" + Number((BalanceToShow * usdPriceToShow).toFixed(4)).toString()}>
                <span className="ml-1 text-secondary">
                  (~{formatDollarAmount(BalanceToShow * usdPriceToShow)})
                </span>
              </Tooltip>
            ) : null}
          </>
        )}{" "}
      </span>
    </div>
  );
};
