type Props = {
  type: "error" | "warning";
  text: string[];
};

const BaseNoticeTiles = ({ type, text }: Props) => {
  return text.length > 0 ? (
    <div
      className={`flex flex-col gap-2 border rounded p-2 bg-opacity-5 mt-3 ${
        type === "warning"
          ? "border-warning bg-warning"
          : "border-error bg-error"
      }`}
    >
      {text.map((singleText, i) => (
        <p
          key={i}
          className={`${
            type === "warning" ? "text-warning" : "text-error"
          } text-sm`}
        >
          {singleText}
        </p>
      ))}
    </div>
  ) : (
    <></>
  );
};

export const ErrorNotice = ({ text }: { text: string[] }) => {
  return <BaseNoticeTiles type="error" text={text} />;
};

export const WarningNotice = ({ text }: { text: string[] }) => {
  return <BaseNoticeTiles type="warning" text={text} />;
};
