import { PublicKey } from "@solana/web3.js";
import { NATIVE_SOL_TOKENINFO, WSOL_TOKENINFO } from "Constants/tokens";

export const validateSolAddress = (address: string) => {
  try {
    let pubkey = new PublicKey(address);
    let isSolana = PublicKey.isOnCurve(pubkey.toBuffer());
    return isSolana;
  } catch (error) {
    return false;
  }
};

export const calculateInputWidth = (str: string) => {
  // Check if the string contains a dot
  const containsDot = str.includes(".");

  // Count the number of '1's in the string
  const countOfOnes = str.split("").filter((char) => char === "1").length;
  const totalLengthWithDot = containsDot ? str.length - 1 : str.length;

  const inputWidth =
    totalLengthWithDot - countOfOnes + countOfOnes * 0.75 + (containsDot ? 0.5 : 0);

  return Number(inputWidth.toFixed(2)) + 0.75;
};

export function validateDecimalPlaces(numStr: string, maxDecimals: number) {
  // Regular expression to check if the number has more than maxDecimals places
  const regex = new RegExp(`^\\d+(\\.\\d{0,${maxDecimals}})?$`);
  return regex.test(numStr);
}

export const checkIfwSOLAddress = (address: string) =>
  address === WSOL_TOKENINFO.address ? NATIVE_SOL_TOKENINFO.address : address;

export function isObject(val: unknown): val is Record<string | number, any> | Array<any> {
  return !(val === null) && typeof val === "object";
}

export function isObjectEmpty(obj: Record<string, any>) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false; // Object is not empty
    }
  }
  return true; // Object is empty
}

export const doesWebsiteMatch = (website: string) => website === window.origin;
