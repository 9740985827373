import { DecimalUtil, Percentage } from "@orca-so/common-sdk";
import {
  Whirlpool,
  increaseLiquidityQuoteByInputTokenUsingPriceSlippage,
  IncreaseLiquidityQuote as OrcaQoute,
  PriceMath,
  IGNORE_CACHE,
} from "@orca-so/whirlpools-sdk";
import { BN } from "@coral-xyz/anchor";
import {
  ApiV3PoolInfoConcentratedItem,
  ClmmRpcData,
  PoolUtils,
  ReturnTypeGetLiquidityAmountOut,
  TickUtils,
} from "@raydium-io/raydium-sdk-v2";
import { EpochInfo, PublicKey } from "@solana/web3.js";
import Decimal from "decimal.js";

import { IOpenPosition } from "Classes/OpenPosition/interface";
import CachedService from "Classes/cachedService";
import { dispatch, store } from "Store";
import { setIsPoolInfoLoading } from "Store/Reducers/loadings";
import { setCurrentPoolPrice, updateTokenAmountsAfterQoute } from "Store/Reducers/open";
import { TokenInputType } from "Types/tokens";
import { exactAmountInDecimals } from "Utils/format";
import { getTickIndexFromTokenPrice } from "Utils/orca/fetcher";

export class OrcaOpenPosition<Q = OrcaQoute, P = Whirlpool> implements IOpenPosition<Q, P> {
  qoute: Q | undefined;
  pool: P | undefined;

  constructor(qoute?: Q, pool?: P) {
    this.qoute = qoute;
    this.pool = pool;
  }

  async fetchPoolData() {
    try {
      const { currentPool, manageLiquidityType } = store.getState().open;
      if (currentPool && CachedService.WhirlpoolClient) {
        dispatch(setIsPoolInfoLoading(true));
        const pool = await CachedService.WhirlpoolClient.getPool(
          new PublicKey(currentPool.address),
          IGNORE_CACHE
        );
        this.pool = pool as P;

        store.dispatch(
          setCurrentPoolPrice({
            price: PriceMath.tickIndexToPrice(
              pool.getData().tickCurrentIndex,
              currentPool.tokenA.decimals,
              currentPool.tokenB.decimals
            ).toNumber(),
            type: manageLiquidityType,
          })
        );
        await this.updateQouteAmounts();
      }
    } catch (error) {
      console.log("Error in fetchPoolData orca: ", error);
    }
    dispatch(setIsPoolInfoLoading(false));
    CachedService.startGetPoolInfoTimer();
  }

  async updateQouteAmounts() {
    const state = store.getState();
    const { slippage, currentSlippage } = state.app;
    const { inputType, amountA, amountB, minPriceRange, maxPriceRange } = state.open;

    const pool = this.pool as Whirlpool | undefined;
    if (pool) {
      try {
        const poolData = pool.getData();
        const tokenAInfo = pool.getTokenAInfo();
        const tokenBInfo = pool.getTokenBInfo();
        const InputTokenInfo = inputType === TokenInputType.A ? tokenAInfo : tokenBInfo;
        const inputAmount = inputType === TokenInputType.A ? amountA : amountB;

        const tickLowerIndex = getTickIndexFromTokenPrice(
          minPriceRange,
          tokenAInfo.decimals,
          tokenBInfo.decimals,
          poolData.tickSpacing
        );
        const tickUpperIndex = getTickIndexFromTokenPrice(
          maxPriceRange,
          tokenAInfo.decimals,
          tokenBInfo.decimals,
          poolData.tickSpacing
        );
        // Get a quote on the estimated liquidity and tokenIn (50 tokenA)
        const updatedQoute = increaseLiquidityQuoteByInputTokenUsingPriceSlippage(
          InputTokenInfo.mint,
          new Decimal(inputAmount),
          tickLowerIndex,
          tickUpperIndex,
          Percentage.fromDecimal(new Decimal(slippage[currentSlippage])),
          pool
        );

        this.qoute = updatedQoute as Q;

        dispatch(
          updateTokenAmountsAfterQoute({
            A:
              inputType === TokenInputType.B
                ? exactAmountInDecimals(
                    DecimalUtil.fromBN(updatedQoute.tokenEstA, tokenAInfo.decimals).toNumber(),
                    tokenAInfo.decimals
                  )
                : undefined,
            B:
              inputType === TokenInputType.A
                ? exactAmountInDecimals(
                    DecimalUtil.fromBN(updatedQoute.tokenEstB, tokenBInfo.decimals).toNumber(),
                    tokenBInfo.decimals
                  )
                : undefined,
          })
        );
      } catch (error) {
        console.log("error", error);
        dispatch(
          updateTokenAmountsAfterQoute({
            A: inputType === TokenInputType.B ? "" : undefined,
            B: inputType === TokenInputType.A ? "" : undefined,
          })
        );
      }
    } else {
      await this.fetchPoolData();
    }
  }

  // Implement other methods here
}

export class RaydiumOpenPosition<
  Q = ReturnTypeGetLiquidityAmountOut,
  P = ApiV3PoolInfoConcentratedItem
> implements IOpenPosition<Q, P>
{
  qoute: Q | undefined;
  pool: P | undefined;
  epochInfo: EpochInfo | undefined;
  rpcPool: ClmmRpcData | undefined;

  constructor(qoute?: Q, pool?: P) {
    this.qoute = qoute;
    this.pool = pool;
  }

  async fetchPoolData() {
    try {
      const raydium = CachedService.raydium;
      // const connection = new Connection(QUICKNODE_MAINNET_RPC);
      // const raydium = await Raydium.load({
      //   connection,
      //   cluster: "mainnet",
      //   // owner: anchrWallet.publicKey,
      //   // signAllTransactions: anchrWallet.signAllTransactions,
      //   jupTokenType: JupTokenType.ALL,
      //   disableLoadToken: true,
      //   disableFeatureCheck: true,
      //   blockhashCommitment: "confirmed",
      // });
      const { currentPool, manageLiquidityType } = store.getState().open;
      if (raydium && currentPool) {
        dispatch(setIsPoolInfoLoading(true));
        const rpcPool = await raydium.clmm.getRpcClmmPoolInfo({ poolId: currentPool.address });
        this.rpcPool = rpcPool;
        store.dispatch(
          setCurrentPoolPrice({ price: rpcPool.currentPrice, type: manageLiquidityType })
        );
      } else {
        console.log("raydium", raydium, "currentPool", currentPool);
      }
      // await this.updateQouteAmounts();
    } catch (error) {
      console.log("Error in fetchPoolData raydium: ", error);
    }
    dispatch(setIsPoolInfoLoading(false));
    CachedService.startGetPoolInfoTimer();
  }

  async updateQouteAmounts() {
    const raydium = CachedService.raydium;
    const state = store.getState();
    const { slippage, currentSlippage } = state.app;
    const { inputType, amountA, amountB, minPriceRange, maxPriceRange, currentPool } = state.open;
    const rpcPool = this.rpcPool;
    let epochInfo = this.epochInfo;
    let poolInfo = this.pool;

    if (rpcPool) {
      try {
        if (raydium && currentPool) {
          if (!poolInfo) {
            const poolData = (await raydium.api.fetchPoolById({
              ids: currentPool.address,
            })) as ApiV3PoolInfoConcentratedItem[];
            this.pool = poolData[0] as P;
            poolInfo = poolData[0] as P;
          }

          if (!epochInfo) {
            const epochData = await raydium.fetchEpochInfo();
            this.epochInfo = epochData;
            epochInfo = epochData;
          }

          const InputTokenInfo =
            inputType === TokenInputType.A ? currentPool.tokenA : currentPool.tokenB;
          const inputAmount = inputType === TokenInputType.A ? amountA : amountB;

          const { tick: lowerTick } = TickUtils.getPriceAndTick({
            poolInfo: poolInfo as ApiV3PoolInfoConcentratedItem,
            price: new Decimal(minPriceRange),
            baseIn: true,
          });

          const { tick: upperTick } = TickUtils.getPriceAndTick({
            poolInfo: poolInfo as ApiV3PoolInfoConcentratedItem,
            price: new Decimal(maxPriceRange),
            baseIn: true,
          });

          const updatedQoute = await PoolUtils.getLiquidityAmountOutFromAmountIn({
            poolInfo: poolInfo as ApiV3PoolInfoConcentratedItem,
            slippage: Number(slippage[currentSlippage]) / 100,
            inputA: inputType === TokenInputType.A,
            tickUpper: Math.max(lowerTick, upperTick),
            tickLower: Math.min(lowerTick, upperTick),
            amount: new BN(
              new Decimal(inputAmount || "0").mul(10 ** InputTokenInfo.decimals).toFixed(0)
            ),
            add: true,
            amountHasFee: true,
            epochInfo,
          });

          this.qoute = updatedQoute as Q;

          dispatch(
            updateTokenAmountsAfterQoute({
              A:
                inputType === TokenInputType.B
                  ? exactAmountInDecimals(
                      DecimalUtil.fromBN(
                        updatedQoute.amountA.amount,
                        currentPool.tokenA.decimals
                      ).toNumber(),
                      currentPool.tokenA.decimals
                    )
                  : undefined,
              B:
                inputType === TokenInputType.A
                  ? exactAmountInDecimals(
                      DecimalUtil.fromBN(
                        updatedQoute.amountSlippageB.amount,
                        currentPool.tokenB.decimals
                      ).toNumber(),
                      currentPool.tokenB.decimals
                    )
                  : undefined,
            })
          );
        }
      } catch (error) {
        console.log("error", error);
        dispatch(
          updateTokenAmountsAfterQoute({
            A: inputType === TokenInputType.B ? "" : undefined,
            B: inputType === TokenInputType.A ? "" : undefined,
          })
        );
      }
    } else {
      await this.fetchPoolData();
    }
  }

  // Implement other methods here
}
