import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { HELIUS_DEVNET_RPC } from "Constants/endpoints";

import { AppReducer, RPC, SlippageLevels, SlippageValues } from "Types/reducers";

const initialState: AppReducer = {
  lastTokensUpdated: Date.now(),
  rpc: RPC._1,
  lastPoolsListUpdated: Date.now(),
  slippage: {
    low: 0.5,
    medium: 1,
    high: 2,
    custom: "",
  },
  currentSlippage: SlippageLevels.medium,
};

const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLastTokensUpdated(state, action) {
      state.lastTokensUpdated = action.payload;
    },
    setLastPoolsListUpdated(state, action) {
      state.lastPoolsListUpdated = action.payload;
    },
    setRPC(state, action: PayloadAction<RPC>) {
      state.rpc = action.payload;
    },
    resetSwapSettings(state) {
      state.slippage = initialState.slippage;
      state.currentSlippage = initialState.currentSlippage;
    },
    saveSwapSettings(
      state,
      action: PayloadAction<{
        currentSlippage: SlippageLevels;
        slippage: SlippageValues;
      }>
    ) {
      state.slippage = action.payload.slippage;
      state.currentSlippage = action.payload.currentSlippage;
    },
    setSlippage(
      state,
      action: PayloadAction<{
        level: SlippageLevels;
        custom: string;
      }>
    ) {
      state.currentSlippage = action.payload.level;
      state.slippage = { ...state.slippage, custom: action.payload.custom };
    },
  },
});

export default app.reducer;

export const {
  setLastTokensUpdated,
  setRPC,
  setLastPoolsListUpdated,
  setSlippage,
  resetSwapSettings,
  saveSwapSettings,
} = app.actions;
