import localforage from "localforage";
import WebWorkers from "web-worker";

import { store } from "Store";
import { setLastTokensUpdated } from "Store/Reducers/app";
import { CachedTokenList } from "Types/tokens";
import { setTokensFetching, setTokensLoading } from "Store/Reducers/loadings";

class TokenCache {
  allTokens: CachedTokenList = {};

  loadTokensList = async () => {
    store.dispatch(setTokensLoading(true));
    const allTokens = await this.getTokensList();
    if (allTokens) {
      this.allTokens = allTokens;
    }
    store.dispatch(setTokensLoading(false));
  };

  getTokensList = async () => {
    try {
      return (await localforage.getItem("all-tokens")) as CachedTokenList | null;
    } catch (error) {
      console.error("Error fetching tokensList from localforage:", error);
      return null;
    }
  };

  setTokensList = async (tokenList: CachedTokenList) => {
    try {
      await localforage.setItem("all-tokens", tokenList);
      store.dispatch(setLastTokensUpdated(Date.now()));
    } catch (error) {
      console.error("Error storing tokensList:", error);
      return null;
    }
  };

  fetchTokensList = async () => {
    const url = new URL("../../public/workers.js", import.meta.url);

    const worker = new WebWorkers(url, { type: "module" });

    worker.postMessage({ action: "fetchTokens" });
    store.dispatch(setTokensFetching(true));

    worker.addEventListener("message", (event) => {
      if (event.data.success) {
        this.setTokensList(event.data.data as CachedTokenList);
      } else {
        console.log("unable to fetch token list in web worker", event.data.error);
      }
      store.dispatch(setTokensFetching(false));
      worker.terminate();
    });
  };
}

const TokenCacheService = new TokenCache();

export default TokenCacheService;
