export const ALCHEMY_RPC =
  "https://solana-mainnet.g.alchemy.com/v2/XZ7JJ4hDzKjlD08C-ffRrd1aHSIT4QrS";

export const HELIUS_DEVNET_RPC = `https://devnet.helius-rpc.com/?api-key=${process.env.REACT_APP_HELIUS_API_KEY}`;
export const HELIUS_MAINNET_RPC = `https://mainnet.helius-rpc.com/?api-key=${process.env.REACT_APP_HELIUS_API_KEY}`;
export const QUICKNODE_MAINNET_RPC =
  "https://sly-frequent-borough.solana-mainnet.quiknode.pro/fe14582373f7556294f669dae74f8a429f8ddde6/";
export const JUPITER_STRICT_LIST = "https://token.jup.ag/strict";
export const JUPITER_ALL_LIST = "https://token.jup.ag/all";
export const JUPITER_PRICING_API = "https://price.jup.ag/v4/price?ids=";
export const ORCA_POOL_LIST = "https://api.mainnet.orca.so/v1/whirlpool/list";
export const COINGECKO_BASEURL = "https://api.coingecko.com/api/v3/";
export const ALL_POOLS_API = "https://liquidity-server.meshprotocol.xyz/pools";
export const MULTIPLE_POOLS_API =
  "https://liquidity-server.meshprotocol.xyz/pools/adresses?adresses=";
// export const ALL_POOLS_API = "http://localhost:3002/pools";
// export const MULTIPLE_POOLS_API = "http://localhost:3002/pools/adresses?adresses=";
export const LIQUIDITY_POOLS_LIST_URL = "https://liquidity-server.meshprotocol.xyz";
