export type TokenInfo = {
  address: string;
  chainId: number;
  decimals: number;
  name: string;
  symbol: string;
  logoURI: string;
  tags: string[];
  extensions?: { coingeckoId?: string };
  weight?: number;
  balance?: number;
};

export type CachedTokenList = Record<string, TokenInfo>;

export enum JupiterTokenListsType {
  strict = "strictTokenList",
  all = "allTokenList",
}

export type ParsedTokenAmount = {
  amount: string;
  decimals: number;
  uiAmount: number;
  uiAmountString: string;
};

export type ParsedTokenInfo = {
  isNative: boolean;
  mint: string;
  owner: string;
  state: string;
  tokenAmount: ParsedTokenAmount;
};

export type UserTokens = Record<string, ParsedTokenAmount>;

export type UserTokenBalances = Record<string, { balance: number | undefined; amount: number }>;

export type JupiterTokenApiRes = TokenInfo[];

export type JupiterPricingResponse = {
  data: {
    [symbol: string]: {
      id: string;
      mintSymbol: string;
      vsToken: string;
      vsTokenSymbol: string;
      price: number;
    };
  };
  timeTaken: number;
};

export type TokensPrices = Record<string, TokenPriceData>;

export type TokenPriceData = {
  price: number;
  priceChange: number | null;
};

export enum TokenInputType {
  A = "A",
  B = "B",
}
