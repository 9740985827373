import { useCallback, useEffect, useMemo } from "react";
import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as walletAdapterWallets from "@solana/wallet-adapter-wallets";
import { WalletError, WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import * as MobileAdapter from "@solana-mobile/wallet-adapter-mobile";
import { ToastContainer } from "react-toastify";

import Routes from "./Routes";
import { QUICKNODE_MAINNET_RPC } from "Constants/endpoints";
import { WalletModalProvider, BaseWalletMultiButton } from "Components/walletModal";
import PoolsCacheService from "Classes/poolCache";
import { shouldFetchPoolsList, shouldFetchTokenList } from "Utils/pools";
import TokenCacheService from "Classes/tokenCache";
import { dispatch, useSelector } from "Store";

import "react-toastify/dist/ReactToastify.css";
import { setIsMorePoolsLoading } from "Store/Reducers/loadings";
require("@solana/wallet-adapter-react-ui/styles.css");

export const WalletButton = BaseWalletMultiButton;

function App() {
  const lastPoolsListUpdated = useSelector((state) => state.app.lastPoolsListUpdated);
  const lastTokensUpdated = useSelector((state) => state.app.lastTokensUpdated);

  useEffect(() => {
    (async () => {
      await PoolsCacheService.loadPoolsList();
      if (await shouldFetchPoolsList(lastPoolsListUpdated)) {
        await PoolsCacheService.fetchPoolsInChunks();
        // await PoolsCacheService.fetchPoolsList(0, -1);
        await PoolsCacheService.loadPoolsList();
        dispatch(setIsMorePoolsLoading(false));
      }

      await TokenCacheService.loadTokensList();
      if (await shouldFetchTokenList(lastTokensUpdated)) {
        await TokenCacheService.fetchTokensList();
        await TokenCacheService.loadTokensList();
      }
    })();
  }, []);

  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          path: "/*",
          element: <Routes />,
          errorElement: <></>,
        },
      ]),
    []
  );
  const wallets = useMemo(
    () => [
      new walletAdapterWallets.PhantomWalletAdapter(),
      new walletAdapterWallets.SolflareWalletAdapter(),
      new walletAdapterWallets.SolongWalletAdapter(),
      new walletAdapterWallets.SafePalWalletAdapter(),
      new walletAdapterWallets.MathWalletAdapter(),
      new walletAdapterWallets.LedgerWalletAdapter(),
      new walletAdapterWallets.HuobiWalletAdapter(),
      new walletAdapterWallets.CoinbaseWalletAdapter(),
      new walletAdapterWallets.CoinhubWalletAdapter(),
      new walletAdapterWallets.Coin98WalletAdapter(),
      new walletAdapterWallets.BitgetWalletAdapter(),
      new MobileAdapter.SolanaMobileWalletAdapter({
        addressSelector: MobileAdapter.createDefaultAddressSelector(),
        appIdentity: {
          name: "Mesh Protocol",
          uri: window.location.href,
          icon: "logo192.png",
        },
        authorizationResultCache: MobileAdapter.createDefaultAuthorizationResultCache(),
        chain: WalletAdapterNetwork.Mainnet,
        // cluster: WalletAdapterNetwork.Mainnet,
        onWalletNotFound: MobileAdapter.createDefaultWalletNotFoundHandler(),
      }),
    ],
    []
  );
  const onError = useCallback((error: WalletError) => {
    console.error(error);
  }, []);
  return (
    // when handle dynamic rpc make sure to check all the custom new Connections()
    <ConnectionProvider endpoint={QUICKNODE_MAINNET_RPC}>
      <WalletProvider wallets={wallets} autoConnect={true} onError={onError}>
        <WalletModalProvider>
          <ToastContainer />
          <RouterProvider router={router} />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
