import { ReactNode } from "react";

import Listeners from "./listeners";
import Footer from "./footer";
import Header from "./header";
import SettingsModal from "Components/Modals/settings";

const MainLayout = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Listeners />
      <SettingsModal />
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default MainLayout;
