import { dispatch, useSelector } from "Store";
import { setSelectedPercentageRange } from "Store/Reducers/open";
import { PercentageRanges } from "Types/pools";

const percentArray = [
  PercentageRanges._1,
  PercentageRanges._5,
  PercentageRanges._10,
  PercentageRanges._20,
  PercentageRanges._50,
];
const PercentChange = () => {
  const selectedMaxPercentageRange = useSelector((state) => state.open.selectedMaxPercentageRange);
  const selectedMinPercentageRange = useSelector((state) => state.open.selectedMinPercentageRange);

  const handleSelect = (val: PercentageRanges) => {
    dispatch(setSelectedPercentageRange({ min: val, max: val }));
  };

  return (
    <div className="flex items-center gap-3">
      <div className="flex items-center gap-4">
        {percentArray.map((item) => (
          <div
            key={item}
            onClick={() => handleSelect(item)}
            className={`py-2 px-4 border border-[#cd42ff94] rounded-lg text-xs text-[#cd42ff94] cursor-pointer ${
              item === selectedMaxPercentageRange && item === selectedMinPercentageRange
                ? "bg-[#cd42ff52]"
                : ""
            }  hover:bg-[#cd42ff52]`}
          >
            &#177; {item}%
          </div>
        ))}
      </div>
      <div
        onClick={() =>
          dispatch(
            setSelectedPercentageRange({ min: PercentageRanges._5, max: PercentageRanges._5 })
          )
        }
        className="text-secondary text-xs cursor-pointer"
      >
        Reset
      </div>
    </div>
  );
};
export default PercentChange;
