import addIcon from "Assets/Svgs/add.svg";

const ExchangeBtn = () => {
  return (
    <div className="flex justify-center my-[-20px] z-10">
      <div
        className={`btn mesh-gradient w-14 h-14 rounded-full button-hover ${
          false ? "cursor-not-allowed opacity-60" : "cursor-pointer opacity-100"
        }`}
      >
        <img
          id="addIcon"
          src={addIcon}
          alt="addIcon"
          style={{
            transition: "0.3s ease-in-out",
            width: 50,
          }}
        />
      </div>
    </div>
  );
};

export default ExchangeBtn;
