import PoolsCacheService from "Classes/poolCache";
import {
  DEPLOYMENT_TIMESTAMP,
  DEPLOYMENT_TIMESTAMP_KEY,
  POOLSLIST_EXPIRE_PERIOD,
  TOKENLIST_EXPIRE_PERIOD,
} from "Constants/misc";
import { LiquidityPositionStatus } from "Types/pools";
import TokenCacheService from "Classes/tokenCache";
import { isObjectEmpty } from "./judger";

export const shouldFetchPoolsList = async (lastPoolsUpdated: number) => {
  const currentTime = Date.now();
  const poolsListIsEmpty = PoolsCacheService.allPools.length === 0;

  const storedDeploymentTimestamp = localStorage.getItem(DEPLOYMENT_TIMESTAMP_KEY);

  // Check if the current time is greater than or equal to 24 hours after lastPoolsUpdated
  // or if tokens does not exist in redux
  const shouldFetch =
    currentTime - lastPoolsUpdated >= POOLSLIST_EXPIRE_PERIOD ||
    poolsListIsEmpty ||
    DEPLOYMENT_TIMESTAMP !== storedDeploymentTimestamp;

  // If shouldFetch is true, update the deployment timestamp in local storage
  if (shouldFetch) {
    localStorage.setItem(DEPLOYMENT_TIMESTAMP_KEY, DEPLOYMENT_TIMESTAMP as string);
  }
  return shouldFetch;
};

export const shouldFetchTokenList = async (lastTokensUpdated: number) => {
  const currentTime = Date.now();
  const tokensListIsEmpty = isObjectEmpty(TokenCacheService.allTokens);
  // Check if the current time is greater than or equal to 24 hours after lastTokensUpdated
  // or if tokens does not exist in redux
  return currentTime - lastTokensUpdated >= TOKENLIST_EXPIRE_PERIOD || tokensListIsEmpty;
};

export function calculateMinMaxPricesRange(
  currentPrice: number,
  minPercentage: number,
  maxPercentage: number
) {
  const max = currentPrice + currentPrice * (maxPercentage / 100);
  const min = currentPrice - currentPrice * (minPercentage / 100);
  return {
    min,
    max,
  };
}

export function calculateMinMaxPercentages(
  currentPrice: number,
  minPrice: number,
  maxPrice: number
) {
  const minPercentage = ((currentPrice - minPrice) / currentPrice) * 100;
  const maxPercentage = ((maxPrice - currentPrice) / currentPrice) * 100;
  return {
    minPercentage,
    maxPercentage,
  };
}

export const updateLiquidityPositionStatus = (
  liquidityPositionStatus: LiquidityPositionStatus,
  currentPoolPrice: number,
  minPrice: number,
  maxPrice: number
) => {
  if (
    minPrice < currentPoolPrice &&
    maxPrice > currentPoolPrice &&
    liquidityPositionStatus !== LiquidityPositionStatus.in_price
  ) {
    return LiquidityPositionStatus.in_price;
  } else if (
    maxPrice < currentPoolPrice &&
    liquidityPositionStatus !== LiquidityPositionStatus.before_price
  ) {
    return LiquidityPositionStatus.before_price;
  } else if (
    minPrice > currentPoolPrice &&
    liquidityPositionStatus !== LiquidityPositionStatus.after_price
  ) {
    return LiquidityPositionStatus.after_price;
  }
};
