import { ChangeEvent, useMemo } from "react";
import debounce from "lodash.debounce";

import { RangeInput } from "./rangeInput";
import PercentChange from "./percentChange";
import { dispatch, useSelector } from "Store";
import {
  setMaxPriceRange,
  setMinPriceRange,
  setSelectedPercentageRange,
} from "Store/Reducers/open";
import { exactAmountInDecimals } from "Utils/format";
import Skeleton from "react-loading-skeleton";
import CachedService from "Classes/cachedService";
import LiquidityChartRangeInput from "Components/LiquidityChartRangeInput";
import { ManageLiqduidityType, FeeAmount } from "Types/pools";

const RangeSelector = () => {
  const currentPool = useSelector((state) => state.open.currentPool);
  const minPriceRange = useSelector((state) => state.open.minPriceRange);
  const maxPriceRange = useSelector((state) => state.open.maxPriceRange);
  const tokenA = useSelector((state) => state.open.tokenA);
  const tokenB = useSelector((state) => state.open.tokenB);
  const currentPoolPrice = useSelector((state) => state.open.currentPoolPrice);
  const isPoolInfoLoading = useSelector((state) => state.loadings.isPoolInfoLoading);
  const isTxInProgress = useSelector((state) => state.loadings.isTxInProgress);
  const selectedMaxPercentageRange = useSelector((state) => state.open.selectedMaxPercentageRange);
  const selectedMinPercentageRange = useSelector((state) => state.open.selectedMinPercentageRange);
  const manageLiquidityType = useSelector((state) => state.open.manageLiquidityType);

  const isDisabled = useMemo(
    () =>
      isTxInProgress || isPoolInfoLoading || manageLiquidityType === ManageLiqduidityType.Increase,
    [manageLiquidityType, isPoolInfoLoading, isTxInProgress]
  );

  const debounced = useMemo(
    () =>
      debounce(() => {
        CachedService.OpenPositionClass.updateQouteAmounts();
      }, 500),
    []
  );

  const handleMinValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setMinPriceRange(Number(e.target.value)));
    debounced();
  };
  const handleMaxValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setMaxPriceRange(Number(e.target.value)));
    debounced();
  };
  const handleMinDecrementChange = () => {
    dispatch(setSelectedPercentageRange({ min: selectedMinPercentageRange + 0.1 }));
    debounced();
  };
  const handleMinIncrementChange = () => {
    dispatch(setSelectedPercentageRange({ min: selectedMinPercentageRange - 0.1 }));
    debounced();
  };
  const handleMaxDecrementChange = () => {
    dispatch(setSelectedPercentageRange({ max: selectedMaxPercentageRange - 0.1 }));
    debounced();
  };
  const handleMaxIncrementChange = () => {
    dispatch(setSelectedPercentageRange({ max: selectedMaxPercentageRange + 0.1 }));
    debounced();
  };
  const handleMinRangeSelectorChange = (val: string) => {
    dispatch(setMinPriceRange(+val));
    debounced();
  };

  const handleMaxRangeSelectorChange = (val: string) => {
    dispatch(setMaxPriceRange(+val));
    debounced();
  };

  return (
    <div className={`p-4 ${isDisabled ? "pointer-events-none" : ""}`}>
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-center font-medium text-primary mb-2 text-xs">
          Current Price:{" "}
          {isPoolInfoLoading ? (
            <Skeleton
              baseColor="#cd42ff"
              highlightColor="#8fb9fc"
              width="50px"
              style={{ opacity: 0.5 }}
            />
          ) : (
            `${exactAmountInDecimals(currentPoolPrice, tokenA.decimals)} ${tokenB.symbol} per ${
              tokenA.symbol
            }`
          )}{" "}
        </div>
        {currentPool?.priceRange && (
          <div className="font-medium text-primary mb-2 text-xs">
            24h Price Range:{" "}
            {`[${exactAmountInDecimals(
              currentPool.priceRange.day.min,
              tokenA.decimals
            )} - ${exactAmountInDecimals(currentPool.priceRange.day.max, tokenA.decimals)}]`}
          </div>
        )}
      </div>
      <div className="h-72 w-full bg-base-100">
        {currentPool ? (
          <LiquidityChartRangeInput
            poolId={currentPool.address}
            price={currentPoolPrice}
            interactive
            baseIn
            autoZoom
            ticksAtLimit={{}}
            feeAmount={FeeAmount.LOWEST}
            priceLower={minPriceRange}
            priceUpper={maxPriceRange}
            onLeftRangeInput={handleMinRangeSelectorChange}
            onRightRangeInput={handleMaxRangeSelectorChange}
            // timePriceMax={currentPool.priceRange.day.max}
            // timePriceMin={currentPool.priceRange.day.min}
          />
        ) : (
          <></>
        )}
        {/* <div className="container">
          <div className="banner-text">Coming soon 🛠️</div>
          <div className="banner"></div>
        </div> */}
      </div>
      <div className="flex items-center gap-6 my-4">
        <div className="w-1/2">
          <div className="text-secondary text-xs mb-3">Min</div>
          <div className="flex items-center gap-4">
            <button
              onClick={handleMinDecrementChange}
              name="minValueDecrement"
              className="font-semibold text-2xl text-purple-500"
            >
              -
            </button>
            <RangeInput
              value={minPriceRange}
              onChange={handleMinValueChange}
              text={`${currentPool?.tokenB.symbol} per ${currentPool?.tokenA.symbol}`}
            />
            <button
              onClick={handleMinIncrementChange}
              name="minValueIncrement"
              className="font-semibold text-2xl text-purple-500"
            >
              +
            </button>
          </div>
        </div>
        <div className="w-1/2">
          <div className="text-secondary text-xs mb-3">Max</div>
          <div className="flex items-center gap-4">
            <button
              onClick={handleMaxDecrementChange}
              name="maxValueDecrement"
              className="font-semi-bold text-2xl text-purple-500"
            >
              -
            </button>
            <RangeInput
              value={maxPriceRange}
              onChange={handleMaxValueChange}
              text={`${currentPool?.tokenB.symbol} per ${currentPool?.tokenA.symbol}`}
            />
            <button
              onClick={handleMaxIncrementChange}
              name="maxValueIncrement"
              className="font-semi-bold text-2xl text-purple-500"
            >
              +
            </button>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between w-full">
        <PercentChange />
        {/* <button className="border border-[#cd42ff94] text-[#cd42ff94] flex items-center gap-2 font-semibold text-xs p-2 rounded-lg">
            CFT Price <img src={SwapIcon} alt="swap" width={10} height={10} />
          </button> */}
      </div>
    </div>
  );
};
export default RangeSelector;
