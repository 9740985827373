import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";

import MeshMobileLogo from "Assets/Svgs/mesh.svg";
import { WalletButton } from "App";
import { navbarLinks, WALLET_BTN_LABELS } from "Constants/misc";
import { Chain } from "Components/chainBox";
import CachedService from "Classes/cachedService";
import { doesWebsiteMatch } from "Utils/judger";

const Header = () => {
  const { pathname } = useLocation();
  const { connected } = useWallet();

  const renderLinks = useCallback(
    () =>
      navbarLinks.map((link) => {
        const doesMatch = doesWebsiteMatch(link.url);
        return (
          <div
            className={"text-sm font-medium accent-on-hover cursor-pointer ".concat(
              doesMatch ? "text-accent" : "text-primary"
            )}
            onClick={() =>
              doesMatch ? CachedService.navigation("/") : window.open(link.url, "_blank")
            }
          >
            {link.name}
          </div>
        );
      }),
    []
  );

  return (
    <div>
      <div className="flex flex-row justify-between items-center bg-black w-full p-4 px-0 sm:px-14">
        <div className="flex gap-12 items-center">
          <div
            className="flex flex-row items-center cursor-pointer"
            onClick={() => CachedService.navigation("/")}
          >
            <img src={MeshMobileLogo} alt="mesh-logo" width={45} height={45} />
          </div>
          <div className="hidden md:flex items-center gap-6">
            {renderLinks()}
            {connected && (
              <div
                onClick={() => CachedService.navigation("/portfolio")}
                className={"text-sm font-medium accent-on-hover cursor-pointer ".concat(
                  pathname === "/portfolio" ? "text-accent" : "text-primary"
                )}
              >
                Portfolio
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center">
          <Chain />
          <div className="hidden md:block">
            <WalletButton
              labels={WALLET_BTN_LABELS}
              style={{ borderRadius: "4px", fontFamily: "inter" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

export const NetworkLabel = () => {
  return (
    <div className="network-label-bg text-sm font-medium text-primary ml-1 sm:ml-1 px-2 py-1 flex items-center rounded-sm">
      Devnet
    </div>
  );
};
