import { useMemo } from "react";
import debounce from "lodash.debounce";

import CachedService from "Classes/cachedService";
import { Input } from "Components/input";
import { dispatch, useSelector } from "Store";
import { setAmountA } from "Store/Reducers/open";
import { setPercentageA } from "Store/Reducers/session";
import { Percentage } from "Types/reducers";
import { TokenInputType } from "Types/tokens";
import { LiquidityPositionStatus } from "Types/pools";

const TokenAInput = () => {
  const amountA = useSelector((state) => state.open.amountA);
  const tokenA = useSelector((state) => state.open.tokenA);
  const liquidityPositionStatus = useSelector((state) => state.open.liquidityPositionStatus);
  const isInSufficientBalanceA = useSelector((state) => state.session.isInSufficientBalanceA);
  const isPriceLoading = useSelector((state) => state.loadings.isPriceLoading);
  const inputType = useSelector((state) => state.open.inputType);

  const debounced = useMemo(
    () =>
      debounce(() => {
        CachedService.OpenPositionClass.updateQouteAmounts();
      }, 500),
    []
  );

  const handleAmountChange = (amount: string) => {
    dispatch(setPercentageA(Percentage._0));
    dispatch(setAmountA(amount));
    debounced();
  };

  const disableInput = useMemo(
    () => liquidityPositionStatus === LiquidityPositionStatus.before_price,
    [liquidityPositionStatus]
  );

  const ontokenClick = () => {};
  return (
    <div
      id="token-input-A-container"
      className={"gradient-border !rounded !mt-3 "
        .concat(inputType === TokenInputType.A ? "" : "gradient-border-transparent ")
        .concat(disableInput ? "pointer-events-none " : "")}
      style={{ filter: disableInput ? "blur(1.5px)" : "" }}
    >
      <div className="bg-base-100 pt-4 px-4 pb-2 rounded">
        <Input
          id="token-input-A"
          amount={amountA}
          token={tokenA}
          setAmount={handleAmountChange}
          subHeading=""
          showPercentage
          ontokenClick={ontokenClick}
          loading={false}
          priceLoading={isPriceLoading}
          isInSufficientBalance={isInSufficientBalanceA}
          editable={true}
          tokenType={TokenInputType.A}
        />
      </div>
    </div>
  );
};

export default TokenAInput;
