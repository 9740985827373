import { lazy } from "react";
import { useRoutes, Navigate, useNavigate } from "react-router-dom";

import CachedService from "Classes/cachedService";
import MainLayout from "Layout/mainlayout";
// import CreatePoolScreen from "Pages/CreatePool";
import AddLiquidity from "Pages/OpenPosition";

const Home = lazy(() => import("Pages/Home"));
const Portfolio = lazy(() => import("Pages/Portfolio"));

export default function Routes() {
  const navigate = useNavigate();

  CachedService.navigation = navigate;

  return useRoutes([
    {
      path: "/",
      element: (
        <MainLayout>
          <Home />
        </MainLayout>
      ),
    },
    // {
    //   path: "/createPool",
    //   element: (
    //     <MainLayout>
    //       <CreatePoolScreen />
    //     </MainLayout>
    //   ),
    // },
    {
      path: "/addLiquidity",
      element: (
        <MainLayout>
          <AddLiquidity />
        </MainLayout>
      ),
    },
    {
      path: "/portfolio",
      element: (
        <MainLayout>
          <Portfolio />
        </MainLayout>
      ),
    },
    { path: "*", element: <Navigate to={"/"} replace={true} /> },
  ]);
}
