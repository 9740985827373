import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LoadingsReducer } from "Types/reducers";

const initialState: LoadingsReducer = {
  tokensLoading: false,
  tokensFetching: false,
  isPriceLoading: false,
  isTxInProgress: false,
  isPoolsLoadingFromCache: false,
  balanceLoading: false,
  isApprovalPending: false,
  isPoolInfoLoading: false,
  isPortfolioLoading: false,
  isInitialPoolsLoading: false,
  isMorePoolsLoading: false,
};

const loadings = createSlice({
  name: "loadings",
  initialState,
  reducers: {
    setIsInitialPoolsLoading(state, action: PayloadAction<boolean>) {
      state.isInitialPoolsLoading = action.payload;
    },
    setIsMorePoolsLoading(state, action: PayloadAction<boolean>) {
      state.isMorePoolsLoading = action.payload;
    },
    setIsPortfolioLoading(state, action: PayloadAction<boolean>) {
      state.isPortfolioLoading = action.payload;
    },
    setIsPriceLoading(state, action: PayloadAction<boolean>) {
      state.isPriceLoading = action.payload;
    },
    setBalanceLoading(state, action: PayloadAction<boolean>) {
      state.balanceLoading = action.payload;
    },
    setIsPoolsLoadingFromCache(state, action: PayloadAction<boolean>) {
      state.isPoolsLoadingFromCache = action.payload;
    },
    setIsApprovalPending(state, action: PayloadAction<boolean>) {
      state.isApprovalPending = action.payload;
    },
    setIsTxInProgress(state, action: PayloadAction<boolean>) {
      state.isTxInProgress = action.payload;
    },
    setTokensFetching(state, action: PayloadAction<boolean>) {
      state.tokensLoading = action.payload;
      state.tokensFetching = action.payload;
    },
    setTokensLoading(state, action: PayloadAction<boolean>) {
      state.tokensLoading = action.payload;
    },
    setIsPoolInfoLoading(state, action: PayloadAction<boolean>) {
      state.isPoolInfoLoading = action.payload;
    },
  },
});

export default loadings.reducer;

export const {
  setIsPriceLoading,
  setTokensLoading,
  setTokensFetching,
  setIsTxInProgress,
  setIsPoolsLoadingFromCache,
  setBalanceLoading,
  setIsApprovalPending,
  setIsPoolInfoLoading,
  setIsPortfolioLoading,
  setIsInitialPoolsLoading,
  setIsMorePoolsLoading,
} = loadings.actions;
