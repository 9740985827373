import { TokenInfo } from "@orca-so/whirlpools-sdk";

export interface OrcaPoolToken {
  mint: string;
  symbol: string;
  name: string;
  decimals: number;
  logoURI: string;
  coingeckoId: string;
  whitelisted: boolean;
  poolToken: boolean;
  token2022: boolean;
}

interface PoolVolume {
  day: number;
  week: number;
  month: number;
}

interface PriceRange {
  min: number;
  max: number;
}

interface PoolPriceRangePeriod {
  day: PriceRange;
  week: PriceRange;
  month: PriceRange;
}

interface PoolApr {
  day: number;
  week: number;
  month: number;
}

// export interface PoolData {
//   address: string;
//   tokenA: OrcaPoolToken;
//   tokenB: OrcaPoolToken;
//   whitelisted: boolean;
//   token2022: boolean;
//   tickSpacing: number;
//   price: number;
//   lpFeeRate: number;
//   protocolFeeRate: number;
//   whirlpoolsConfig: string;
//   modifiedTimeMs: number;
//   tvl: number;
//   volume: PoolVolume;
//   volumeDenominatedA: PoolVolume;
//   volumeDenominatedB: PoolVolume;
//   priceRange?: PoolPriceRangePeriod;
//   feeApr: PoolApr;
//   reward0Apr: PoolApr;
//   reward1Apr: PoolApr;
//   reward2Apr: PoolApr;
//   totalApr: PoolApr;
//   weight?: number;
// }

export enum DexType {
  ORCA = "ORCA",
  RAYDIUM = "RAYDIUM",
}

export interface Token {
  mint: string; // mintA, address
  symbol: string;
  name: string;
  decimals: number;
  logoURI: string;
  coingeckoId?: string;
  whitelisted?: boolean;
  token2022: boolean; // match programId
}
export interface PoolData {
  programId: string;
  address: string; // id
  tokenA: Token;
  tokenB: Token; // same as tokenA,
  tickSpacing: number;
  price: number;
  lpFeeRate: number; // feeRate (calculate on server)
  protocolFeeRate: number;
  poolConfig: string; // config, id
  tvl: number;
  volume: PoolVolume;
  priceRange?: PoolPriceRangePeriod; // priceMin, priceMax
  feeApr: PoolApr;
  totalApr: PoolApr; // apr
  dexType: DexType;
  weight?: number;
}

export type PoolTokenInfo = TokenInfo;

export enum PercentageRanges {
  _0 = 0,
  _1 = 1,
  _5 = 5,
  _10 = 10,
  _20 = 20,
  _50 = 50,
}

export enum LiquidityPositionStatus {
  before_price = "before_price",
  in_price = "in_price",
  after_price = "after_price",
}

export enum Bound {
  LOWER = "LOWER",
  UPPER = "UPPER",
}

export enum FeeAmount {
  LOWEST = 100,
  LOW = 500,
  MEDIUM = 2500,
  HIGH = 10000,
}

export type PositionPoolToken = {
  mint: string;
  symbol: string;
  name: string;
  decimals: number;
  logoURI: string;
};

export type UserPositionInfo = {
  positionAddress: string;
  positionNFTaddress: string;
  priceLower: string;
  priceUpper: string;
  dexType: DexType;
  poolAddress: string;
  tickSpacing: number;
  currentPrice: string;
  amountTokenA: string;
  amountTokenB: string;
  tvl?: number;
  volume?: PoolVolume;
  apr?: PoolApr;
  usdPriceTokenA?: string;
  usdPriceTokenB?: string;
  infoTokenA: PositionPoolToken;
  infoTokenB: PositionPoolToken;
  feeTier: number;
  tickLower: number;
  tickUpper: number;
  liquidity: string;
};

export enum ManageLiqduidityType {
  Open = "Open",
  Increase = "Increase",
  Withdraw = "Withdraw",
  decrease = "decrease",
}
