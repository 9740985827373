import { useMemo } from "react";

import { useSelector } from "Store";
import { formatDollarAmount } from "Utils/format";

const PriceCalculator = () => {
  const tokenA = useSelector((state) => state.open.tokenA);
  const amountA = useSelector((state) => state.open.amountA);
  const tokenB = useSelector((state) => state.open.tokenB);
  const amountB = useSelector((state) => state.open.amountB);
  const currentPrices = useSelector((state) => state.session.currentPrices);

  const total = useMemo(() => {
    const tokenAPrice = currentPrices[tokenA.address]?.price;
    const tokenBPrice = currentPrices[tokenB.address]?.price;
    if (tokenAPrice && tokenBPrice) {
      return +amountA * tokenAPrice + +amountB * tokenBPrice;
    } else return undefined;
  }, [amountA, amountB, currentPrices, tokenA.address, tokenB.address]);
  return (
    <div className="flex items-center justify-between bg-base-100 text-sm mt-1 py-2 px-3 rounded mx-[2px]">
      <p className="font-semibold text-primary text-lg">Total</p>
      <p className="font-semibold text-primary text-lg">{formatDollarAmount(total)}</p>
    </div>
  );
};

export default PriceCalculator;
