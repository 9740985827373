import { useMemo } from "react";

import { SlippageLevels, SlippageValues } from "Types/reducers";
import InfoIcon from "Assets/Svgs/info.svg";

type Props = {
  slippage: SlippageValues;
  currentSlippage: SlippageLevels;
  onSlippageClick: (level: SlippageLevels) => void;
  onCustomSlippageChange: (num: string) => void;
};

const SlippageSelection = ({
  slippage,
  currentSlippage,
  onSlippageClick,
  onCustomSlippageChange,
}: Props) => {
  const showWarning = useMemo(() => {
    const val = slippage[currentSlippage];
    return typeof val === "number" ? val < 1 : val !== "" && Number(val) < 1;
  }, [currentSlippage, slippage]);
  return (
    <div>
      <p className="text-secondary text-sm">Slippage tolerance</p>
      <div className="relative text-primary flex items-center justify-between gap-2 h-12 py-2">
        <SlippageButton
          value={slippage[SlippageLevels.low]}
          isSelected={currentSlippage === SlippageLevels.low}
          onClick={() => onSlippageClick(SlippageLevels.low)}
        />
        <SlippageButton
          value={slippage[SlippageLevels.medium]}
          isSelected={currentSlippage === SlippageLevels.medium}
          onClick={() => onSlippageClick(SlippageLevels.medium)}
        />
        <SlippageButton
          value={slippage[SlippageLevels.high]}
          isSelected={currentSlippage === SlippageLevels.high}
          onClick={() => onSlippageClick(SlippageLevels.high)}
        />
        <div
          className={"flex items-center justify-between gradient-border !rounded cursor-pointer w-full h-full".concat(
            currentSlippage === SlippageLevels.custom
              ? ""
              : " gradient-border-secondary"
          )}
        >
          <input
            value={slippage.custom}
            type="text"
            placeholder="Custom"
            className="input w-full h-full rounded-l"
            onChange={(e) => onCustomSlippageChange(e.target.value)}
            onFocus={() => onSlippageClick(SlippageLevels.custom)}
          />
          <p className="text-secondary bg-base-100 rounded-r h-full flex items-center px-2 font-semibold">
            %
          </p>
        </div>
      </div>
      {showWarning && (
        <div className="flex items-center">
          <img
            src={InfoIcon}
            alt="InfoIcon"
            style={{
              filter:
                "brightness(0) saturate(100%) invert(75%) sepia(73%) saturate(580%) hue-rotate(334deg) brightness(106%) contrast(102%)",
            }}
          />
          <p className="ml-1 text-sm font-bold text-warning">
            Your transaction may fail
          </p>
        </div>
      )}
    </div>
  );
};

export default SlippageSelection;

type SlippageButtonProps = {
  value: number;
  isSelected?: boolean;
  onClick?: () => void;
  twContainerClass?: string;
};

const SlippageButton = ({
  value,
  isSelected = false,
  onClick,
  twContainerClass = "",
}: SlippageButtonProps) => {
  return (
    <div
      className={"gradient-border !rounded cursor-pointer w-[25%] h-full "
        .concat(isSelected ? "" : "gradient-border-secondary ")
        .concat(twContainerClass)}
      onClick={onClick}
    >
      <div className="h-full text-center rounded bg-base-100 flex items-center justify-center py-1 px-2.5">
        <p
          className={
            isSelected ? "font-semibold mesh-text-gradient" : "text-secondary"
          }
        >
          {value}%
        </p>
      </div>
    </div>
  );
};
