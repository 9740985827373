import solanaLogo from "Assets/Svgs/solana.svg";
import { useTwBreakpoints } from "Hooks/useTwBreakpoints";
import { dispatch, useSelector } from "Store";
import { Environment, TwBreakPoints } from "Types/misc";
import { RPC } from "Types/reducers";
import checkIcon from "Assets/Svgs/check_circle.svg";
import { setRPC } from "Store/Reducers/app";
import arrowDownIcon from "Assets/Svgs/arrow_down.svg";

export const Chain = () => {
  const screenSize = useTwBreakpoints();
  const { rpc } = useSelector((state) => state.app);

  const handleClick = (thisRpc: RPC) => {
    const elem: any = document.activeElement;
    if (elem) {
      elem?.blur(); // to close dropdown
    }
    if (thisRpc !== rpc) {
      dispatch(setRPC(thisRpc));
    }
  };
  return (
    <div className="dropdown dropdown-bottom w-max mr-4 sm:mr-4 text-primary">
      <div
        tabIndex={0}
        role="button"
        className="px-3 py-2 flex items-center bg-base-content rounded"
      >
        <img src={solanaLogo} alt="ringMeshIcon" width={25} height={25} />
        <p className="text-primary font-extrabold px-1">
          {process.env.REACT_APP_ENVIRONMENT === Environment.dev
            ? screenSize === TwBreakPoints.sm || screenSize === TwBreakPoints.xs
              ? "Devnet"
              : "Solana Devnet"
            : "Solana"}
        </p>
        <img src={arrowDownIcon} alt="arrowDownIcon" className="ml-2 w-4" />
      </div>
      <ul
        tabIndex={0}
        className="dropdown-content z-[1] menu p-2 shadow bg-[#2c2d30] w-[100%]  rounded mt-2"
      >
        <li
          onClick={() => handleClick(RPC._1)}
          className="hover:bg-base-300 focus:bg-transparent flex flex-row items-center justify-around rounded cursor-pointer py-3"
        >
          <div className="text-xs active:!bg-transparent active:!text-white p-0">RPC 1</div>
          <img
            src={checkIcon}
            alt="checkIcon"
            className="w-4 h-4 p-0"
            style={{ opacity: rpc === RPC._1 ? 1 : 0 }}
          />
        </li>
        <li
          onClick={() => handleClick(RPC._2)}
          className="hover:bg-base-300 focus:bg-transparent flex flex-row items-center justify-around cursor-pointer rounded py-3"
        >
          <div className="text-xs flex items-center justify-center active:!bg-transparent active:!text-white p-0">
            RPC 2
          </div>
          <img
            src={checkIcon}
            alt="checkIcon"
            className="w-4 h-4 p-0"
            style={{ opacity: rpc === RPC._2 ? 1 : 0 }}
          />
        </li>
      </ul>
    </div>
  );
};
