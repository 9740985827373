import { Oval } from "react-loader-spinner";
import {
  WhirlpoolAccountFetcher,
  WhirlpoolClient,
  WhirlpoolContext,
  buildDefaultAccountFetcher,
  buildWhirlpoolClient,
} from "@orca-so/whirlpools-sdk";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import { Connection } from "@solana/web3.js";
import { ToastOptions, toast } from "react-toastify";
import { NavigateFunction } from "react-router";
import { JupTokenType, Raydium } from "@raydium-io/raydium-sdk-v2";

import { WHIRLPOOL_PROGRAM_ID } from "Constants/address";
import checkIcon from "Assets/Svgs/checkIcon.svg";
import errorIcon from "Assets/Svgs/txReject.svg";
import warningIcon from "Assets/Svgs/warning.svg";
import { OpenPositionFactory } from "./OpenPosition/factory";
import { DexType } from "Types/pools";
// import { fetchUserTokens } from "Utils/fetchers";

const commonToastConfig: Partial<ToastOptions> = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
  bodyClassName: "info-toast-body",
};

class Cache {
  navigation: NavigateFunction = () => {};
  startGetPoolInfoTimer: () => void = () => {};
  stopGetPoolInfoTimer: () => void = () => {};
  raydium: Raydium | undefined;
  WhirlpoolContext: WhirlpoolContext | undefined = undefined;
  WhirlpoolFetcher: WhirlpoolAccountFetcher | undefined = undefined;
  WhirlpoolClient: WhirlpoolClient | undefined = undefined;

  OpenPositionClass = OpenPositionFactory.chooseSDK(DexType.ORCA);

  setupWhirlpool(connection: Connection, anchrWallet: AnchorWallet) {
    const ctx = WhirlpoolContext.from(connection, anchrWallet, WHIRLPOOL_PROGRAM_ID);
    this.WhirlpoolContext = ctx;
    this.WhirlpoolFetcher = buildDefaultAccountFetcher(ctx.provider.connection);
    this.WhirlpoolClient = buildWhirlpoolClient(ctx);
  }

  setOpenPositionSDK(dexSDK: DexType) {
    this.OpenPositionClass = OpenPositionFactory.chooseSDK(dexSDK);
  }

  async setupRaydium(connection: Connection, anchrWallet: AnchorWallet) {
    this.raydium = await Raydium.load({
      connection,
      cluster: "mainnet",
      owner: anchrWallet.publicKey,
      signAllTransactions: anchrWallet.signAllTransactions,
      jupTokenType: JupTokenType.ALL,
      disableLoadToken: true,
      disableFeatureCheck: true,
      blockhashCommitment: "confirmed",
    });
    // // update user tokens on any change trigger in user wallet
    // connection.onAccountChange(anchrWallet.publicKey, async () => {
    //   await fetchUserTokens(connection, anchrWallet.publicKey);
    // });
  }

  errorToast = (text: string | JSX.Element, options?: ToastOptions<{}>) =>
    toast.error(text, {
      ...commonToastConfig,
      bodyClassName: "",
      icon: ({ theme, type }) => (
        <img src={errorIcon} alt="checkIcon" style={{ marginTop: "3px" }} />
      ),
      style: {
        backgroundImage: "linear-gradient(139deg, rgb(0, 0, 0), 72%, rgba(240, 66, 72, 0.3))",
      },
      ...options,
    });

  TxProgressToast = (text: string | JSX.Element, options?: ToastOptions<{}>) =>
    toast.error(text, {
      ...commonToastConfig,
      icon: ({ theme, type }) => (
        <Oval
          color="#cd42ff"
          secondaryColor="rgba(255, 255, 255, 0.1)"
          strokeWidth={7}
          width={25}
          height={25}
          wrapperStyle={{ marginTop: "3px" }}
        />
      ),
      style: {
        backgroundImage: "linear-gradient(139deg, rgb(0, 0, 0), 72%, rgba(255, 210, 31, 0.3))",
      },
      autoClose: false,
      ...options,
    });

  warningToast = (text: string | JSX.Element, options?: ToastOptions<{}>) =>
    toast.warning(text, {
      ...commonToastConfig,
      icon: ({ theme, type }) => (
        <img src={warningIcon} alt="checkIcon" style={{ marginTop: "3px" }} />
      ),
      style: {
        backgroundImage: "linear-gradient(139deg, rgb(0, 0, 0), 72%, rgba(255, 210, 31, 0.3))",
      },
      ...options,
    });

  successToast = (text: string | JSX.Element, options?: ToastOptions<{}>) =>
    toast.success(text, {
      ...commonToastConfig,
      icon: ({ theme, type }) => (
        <img src={checkIcon} alt="checkIcon" style={{ marginTop: "3px" }} />
      ),
      style: {
        backgroundImage: "linear-gradient(139deg, rgb(0, 0, 0), 72%, rgba(0, 223, 128, 0.2))",
      },
      ...options,
    });
}

const CachedService = new Cache();

export default CachedService;
