import { ManageLiqduidityType, LiquidityPositionStatus, PoolData, UserPositionInfo } from "./pools";
import { TokenInfo, TokenInputType, TokensPrices, UserTokenBalances, UserTokens } from "./tokens";

export type SessionReducer = {
  userTokens: UserTokens;
  percentageA: Percentage;
  percentageB: Percentage;
  successTxCount: number;
  userTokenBalances: UserTokenBalances;
  isInSufficientBalanceA: boolean;
  isInSufficientBalanceB: boolean;
  currentPrices: TokensPrices;
  userBalanceA: string;
  userBalanceB: string;
  isSettingsOpen: boolean;
  tab: DexTabs;
};

export enum DexTabs {
  ALL = "all",
  RAYDIUM = "raydium",
  ORCA = "orca",
}

export type AppReducer = {
  lastTokensUpdated: number;
  rpc: RPC;
  lastPoolsListUpdated: number;
  slippage: SlippageValues;
  currentSlippage: SlippageLevels;
};
export type LoadingsReducer = {
  isPriceLoading: boolean;
  tokensLoading: boolean;
  tokensFetching: boolean;
  isTxInProgress: boolean;
  isPoolsLoadingFromCache: boolean;
  balanceLoading: boolean;
  isApprovalPending: boolean;
  isPoolInfoLoading: boolean;
  isPortfolioLoading: boolean;
  isInitialPoolsLoading: boolean;
  isMorePoolsLoading: boolean;
};

export type PortfolioReducer = {
  positions: UserPositionInfo[];
};

export type openReducer = {
  tokenA: TokenInfo;
  tokenB: TokenInfo;
  amountA: string;
  amountB: string;
  currentPool?: PoolData;
  inputType: TokenInputType;
  minPriceRange: number;
  maxPriceRange: number;
  currentPoolPrice: number;
  selectedMinPercentageRange: number;
  selectedMaxPercentageRange: number;
  errors: string[];
  liquidityPositionStatus: LiquidityPositionStatus;
  manageLiquidityType: ManageLiqduidityType;
  currentPosition: UserPositionInfo | undefined;
};

export enum RPC {
  _1 = 1,
  _2 = 2,
}

export enum Percentage {
  _0 = 0,
  _10 = 0.1,
  _25 = 0.25,
  _50 = 0.5,
  _75 = 0.75,
  _100 = 1,
}

export enum SlippageLevels {
  low = "low",
  medium = "medium",
  high = "high",
  custom = "custom",
}

export type SlippageValues = {
  [key in keyof typeof SlippageLevels]: key extends "custom" ? string : number;
};
